<script>
  import Button from "@/Components/Buttons/Button.svelte";

  import { loadStripe } from "@stripe/stripe-js";
  import {Address, Elements, PaymentElement} from "svelte-stripe";
  import { onMount } from "svelte";
  import PaymentCard from "@/Components/Shop/Card.svelte";
  import { failure, success } from "@/Lib/notices";
  import api from "@/Lib/api";
  import { page, inertia, router } from "@inertiajs/svelte";
  import Credit from "./Credit.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";

  export let stripe_key;
  export let payment_methods;
  export let client_secret;

  let stripe = null;
  let error = null;
  let elements;
  let processing = false;
  let loading = false;
  let new_method = payment_methods.length === 0;
  let selected_method;
  let credit_method_available = false;

  onMount(async () => {
    stripe = await loadStripe(stripe_key);
  });

  if ($page.props?.credit && $page.props?.pitch?.request_fee && parseInt($page.props?.credit) >= parseInt($page.props?.pitch?.request_fee)) {
    credit_method_available = true;
  }

  const updateMethod = () => {
    if (selected_method !== null) {
      new_method = false;
    }
  };

  const addNewMethod = () => {
    selected_method = null;
    new_method = true;
    loading = true;
  };

  const redeemCoupon = () => {
    selected_method = null;
    credit_method_available = true;
  };

  const handleSubmit = async () => {
    // avoid processing duplicates
    if (processing) return;

    processing = true;

    if (!selected_method && !new_method) {
      failure("Please select a payment method");
      processing = false;
      return;
    }

    if (new_method) {
      await stripe
        .confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url: `${$page.props.common.app_url}/pitch/${$page.props?.pitch?.id}/verify-payment`,
          },
        })
        .then(async (result) => {
          await handleResponse(result);
        })
        .catch(() => {
          processing = false;
        });

      // Todo: add catch statement for stripe errors
    }

    if (selected_method === "credit") {
      await api
        .post(`/pitch/${$page.props?.pitch?.id}/credit-payment`)
        .then((res) => res.data)
        .then((res) => {
          if (res.error) {
            failure(res.error);
            router.visit(`/pitch/${$page.props?.pitch?.id}/set-rate`);
          } else {
            success(
              "Payment details successfully processed, your pitch has been sent",
            );

            let pitchData = localStorage.getItem(
              `pitch_data_${$page.props.profile?.id}`,
            );
            if (pitchData) {
              localStorage.removeItem(`pitch_data_${$page.props.profile?.id}`);
            }

            if (res.redirect) {
              router.visit(res.redirect);
            } else {
              router.visit("/app/marketplace");
            }
          }
        })
        .catch((e) => {
          processing = false;
          console.log(e);
          failure(e.message);
        });

      return;
    }
    else {
      // confirm payment with stripe
      await stripe
        .confirmCardSetup(client_secret, {
          payment_method: selected_method,
          return_url: `${$page.props.common.app_url}/pitch/${$page.props?.pitch?.id}/verify-payment`,
        })
        .then(async (result) => {
          await handleResponse(result);
        })
        .catch(() => {
          processing = false;
        });

      // Todo: add catch statement for stripe errors
    }
  };

  const handleResponse = async (result) => {
    if (result.error) {
      // payment failed, notify user
      error = result.error;
      failure(error.message);
      processing = false;
    } else {
      await api
        .post(`/pitch/${$page.props?.pitch?.id}/authorize-payment`, {
          intent_id: result.setupIntent.id,
          payment_method: result.setupIntent.payment_method,
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.error) {
            failure(res.error);
            router.visit(`/pitch/${$page.props?.pitch?.id}/set-rate`);
          } else {
            success(
              "Payment details successfully processed, your pitch has been sent",
            );

            let pitchData = localStorage.getItem(
              `pitch_data_${$page.props.profile?.id}`,
            );
            if (pitchData) {
              localStorage.removeItem(`pitch_data_${$page.props.profile?.id}`);
            }

            if (res.redirect) {
              router.visit(res.redirect);
            } else {
              router.visit("/app/marketplace");
            }
          }
        })
        .catch((e) => {
          processing = false;
          console.log(e);
          failure(e.message);
        });
    }
  };
</script>

{#if stripe}
  <form on:submit|preventDefault={handleSubmit}>
    {#if !new_method && payment_methods?.length > 0}
      <h2>Select a Payment Method</h2>
      <div class="cards">
        {#each payment_methods.filter(m => !m.expired) as method}
          <label>
            <input
              type="radio"
              bind:group={selected_method}
              value={method.id}
              on:change={updateMethod}
            />
            <PaymentCard
              class={selected_method === method.id && 'active'}
              theme="mini"
              id={method.id}
              brand={method.brand}
              brand_formatted={method.brand_formatted}
              expiry={method.expiry}
              last4={method.last4}
              name={method.name}
              default_method={method.default_method}
              expired={method.expired}
            />
          </label>
        {/each}

        {#if credit_method_available}
          <label class:active={selected_method === 'credit'}>
            <input bind:group={selected_method} value="credit" type="radio" checked="checked" />
            <Credit theme="mini" class={selected_method === 'credit' && 'active'} />
          </label>
        {/if}

        <div class="actions">
          {#if !credit_method_available}<button on:click|preventDefault={() => redeemCoupon()} class="btn-white">Redeem Coupon</button>{/if}
          <button on:click|preventDefault={() => addNewMethod()} class="btn-white">Add New Payment Method</button>
        </div>
      </div>
    {/if}

    {#if new_method}
      <h2>Add a Credit Card</h2>
      {#if loading}
        <Loader />
      {/if}
      <div class="elements">
        <Elements {stripe} clientSecret={client_secret} bind:elements>
          <PaymentElement on:ready={() => loading = false} />
          <Address
            mode="billing"
            defaultValues={$page.props?.address_defaults}
          />
        </Elements>
      </div>
    {/if}

    <div class="footer">
      <div class="actions">
        <a
          class="back"
          href="/pitch/{$page.props?.pitch?.id}/set-rate"
          use:inertia={{ preserveScroll: true }}>&lt; Back</a
        >
        <Button
          type="submit"
          color="red"
          size="sm"
          class="!px-8"
          on:submit={() => handleSubmit()}
          disabled={!selected_method && !new_method}
          loading={processing}>Done</Button
        >
      </div>
    </div>
  </form>
{/if}

<style lang="postcss">
  .elements {
    @apply px-6 py-4 my-4 rounded-lg bg-blue-100 max-w-3xl mx-auto;
  }

  h2 {
    @apply text-xl md:text-3xl font-semibold text-white text-center;
  }

  .cards {
    @apply md:px-10 py-8 flex gap-4 flex-col items-center;

    label {
      @apply flex gap-2 sm:gap-4;
    }
  }

  .actions {
    @apply flex justify-between items-center max-w-3xl mx-auto gap-4;
  }

  .footer {
    @apply px-10 mt-4 bg-blue-800 rounded-b-2xl text-right;

    a {
      @apply text-white;
    }
  }
</style>
