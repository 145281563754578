<script>
  // Adapted from https://github.com/themesberg/flowbite-svelte/blob/main/src/lib/modals/Modal.svelte

  import classNames from 'classnames';
  import {createEventDispatcher} from 'svelte';
  import CloseButton from '../Buttons/CloseButton.svelte';
  import focusTrap from '@/Lib/utils/focusTrap';
  import {fade} from 'svelte/transition';

  export let open = false;
  export let title = '';

  /** @type {import('@/Lib/types/component.d').SizeType} size */
  export let size = 'md';

  /** @type {import('@/Lib/types/component.d').ModalPlacementType} placement */
  export let placement = 'center';
  export let autoClose = true;
  export let permanent = false;

  const dispatch = createEventDispatcher();
  $: dispatch(open ? 'open' : 'hide');

  /** @param {MouseEvent} e */
  const onAutoClose = (e) => {
    if (autoClose) hide(e);
  };

  /** @param {Event} e */
  const hide = (e) => {
    e.preventDefault();
    open = false;
  };

  /** @param {KeyboardEvent} e */
  function handleKeys(e) {
    if (e.key === 'Escape' && !permanent) return hide(e);
  }
</script>

{#if open}
  <div
    class="backdrop"
    role="presentation"
    on:click={autoClose ? onAutoClose : null}
    on:keydown
    on:keypress
    on:keyup
  ></div>

  <div
    use:focusTrap
    class={classNames('modal', placement, $$props.class)}
    tabindex="-1"
    aria-modal="true"
    role="dialog">
    <div class={classNames('wrap', size)}>
      <div {...$$restProps} class={classNames('content', $$slots.sidebar && 'has-sidebar')} transition:fade>

        {#if $$slots.sidebar}
          <div class="sidebar">
            <slot name="sidebar" />
          </div>
        {/if}

        <div class="outer">
          {#if $$slots.header || title}
            <div class="header">
              <slot name="header">
                <h3>{title}</h3>
              </slot>
              {#if !permanent}
                <CloseButton name="Close modal" on:click={hide} />
              {/if}
            </div>
          {:else if !permanent}
            <CloseButton name="Close modal" class="absolute top-1.5 right-1.5" on:click={hide} />
          {/if}

          <div class="body" role="presentation" on:keydown|stopPropagation={handleKeys}>
            <slot />
          </div>

          {#if $$slots.footer}
            <div class="footer">
              <slot name="footer" />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="postcss">
  .backdrop {
    @apply backdrop-blur fixed inset-0 z-40 bg-grey-800 bg-opacity-50;
  }

  .modal {
    @apply fixed top-0 left-0 right-0 md:inset-0 md:h-full z-50 w-full p-4 flex;

    &.top-left {
      @apply justify-start items-start;
    }

    &.top-center {
      @apply justify-center items-start;
    }

    &.top-right {
      @apply justify-end items-start;
    }

    &.center-left {
      @apply justify-start items-center;
    }

    &.center {
      @apply justify-center items-center;
    }

    &.center-right {
      @apply justify-end items-center;
    }

    &.bottom-left {
      @apply justify-start items-end;
    }

    &.bottom-center {
      @apply justify-center items-end;
    }

    &.bottom-right {
      @apply justify-end items-end;
    }
  }

  .wrap {
    @apply flex relative w-full max-h-full justify-center;

    &.xs {
      @apply max-w-md;
    }

    &.sm {
      @apply max-w-lg;
    }

    &.md {
      @apply max-w-2xl;
    }

    &.lg {
      @apply max-w-4xl;
    }

    &.xl {
      @apply max-w-7xl;
    }
  }

  .sidebar {
    @apply col-span-1 bg-grey-100 px-3 py-4 border-r border-grey-200 rounded-l-xl;
  }

  .content {
    @apply border-2 bg-white border-grey-200 shadow-lg rounded-xl relative w-full;

    &.has-sidebar {
      @apply grid grid-cols-3;

      .outer {
        @apply col-span-2 px-6 py-4;
      }

      .header {
        @apply p-0 mb-4;
      }

      .body {
        @apply p-0
      }
    }
  }

  .header {
    @apply flex justify-between items-center p-4 rounded-t;

    h3 {
      @apply text-xl font-semibold text-grey-800 p-0;
    }
  }

  .body {
    @apply p-7 space-y-6 flex-1;
  }

  .footer {
    @apply flex items-center p-6 space-x-2 rounded-b border-t;
  }
</style>
