<script>
  import Button from "@/Components/Buttons/Button.svelte";

  import {loadStripe} from '@stripe/stripe-js'
  import {Address, Elements, PaymentElement} from 'svelte-stripe'
  import {onMount} from 'svelte'
  import PaymentCard from "@/Components/Shop/Card.svelte";
  import {failure} from "@/Lib/notices";
  import {page, inertia, useForm} from "@inertiajs/svelte";
  import Loader from "@/Components/Elements/Loader.svelte";

  export let stripe_key;
  export let payment_methods;
  export let client_secret;

  let stripe = null;
  let error = null;
  let elements;
  let processing = false;
  let loading = false;
  let new_method = payment_methods.length === 0;
  let selected_method;

  onMount(async () => {
    stripe = await loadStripe(stripe_key);
  })

  const updateMethod = () => {
    if (selected_method !== null) {
      new_method = false
    }
  }

  const addNewMethod = () => {
    processing = false;
    selected_method = null;
    new_method = true;
    loading = true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Stripe.js hasn't yet loaded.
    if (!stripe) {
      return;
    }

    // avoid processing duplicates
    if (processing) {
      return;
    }
    processing = true;

    // create payment method
    if (elements) {
      const {error: submitError} = await elements.submit();
      if (submitError) {
        failure(submitError.message);
        console.error(submitError);
        return;
      }
    }

    // confirm payment with stripe
    if (selected_method) {
      await stripe.confirmCardPayment(client_secret, {
        payment_method: selected_method,
        // return_url: `${$page.props.common.app_url}/app/campaign-links/${$page.props?.campaign_link?.id}/verify-payment`
      }).then(async (result) => {
        await handleResponse(result);
      }).catch(() => {
        processing = false
      })

      // Todo: add catch statement for stripe errors
    } else {
      // Use the clientSecret and Elements instance to confirm the setup
      await stripe.confirmPayment({
        elements,
        clientSecret: client_secret,
        redirect: 'if_required',
        confirmParams: {
          // return_url: `${$page.props.common.app_url}/app/campaign-links/${$page.props?.campaign_link?.id}/verify-payment`
        }
      }).then(async (result) => {
        await handleResponse(result);
      }).catch((e) => {
        console.log(e);
        processing = false
      })

      // Todo: add catch statement for stripe errors
    }
  }

  let form = useForm({
    intent_id: null,
    payment_method: null
  });

  const handleResponse = async (result) => {
    if (result.error) {
      // payment failed, notify user
      error = result.error
      failure(error.message)
      console.error(error)
      processing = false
    } else {
      $form.intent_id = result.paymentIntent.id;
      $form.payment_method = result.paymentIntent.payment_method;
      $form.post(`/app/campaign-links/${$page.props?.campaign_link?.id}/authorize-payment`, {
        onSuccess: () => {
          processing = false
          //   inertia.visit(`/app/campaign-links/${$page.props?.campaign_link?.id}/verify-payment`)
        },
        onError: () => {
          processing = false
        }
      });
    }
  }
</script>

{#if stripe}
  <form on:submit={(event) => handleSubmit(event)}>
    {#if payment_methods?.length > 0}
      <div class="cards">
        {#each payment_methods.filter(m => !m.expired) as method}
          <label>
            <input type="radio" bind:group={selected_method} value={method.id} on:change={updateMethod} />
            <PaymentCard
              theme="mini"
              id={method.id}
              brand={method.brand}
              brand_formatted={method.brand_formatted}
              expiry={method.expiry}
              last4={method.last4}
              name={method.name}
              default_method={method.default_method}
              expired={method.expired}
            />
          </label>
        {/each}

        <div class="actions">
          <Button on:click={() => addNewMethod()} size="xxs" color="tertiary">Add New Payment Method</Button>
        </div>
      </div>
    {/if}

    {#if new_method}
      {#if loading}
        <Loader />
      {/if}
      <div class="elements">
        <Elements {stripe} clientSecret={client_secret} bind:elements>
          <PaymentElement on:ready={() => loading = false} />
          <Address
            mode="billing"
            defaultValues={$page.props?.address_defaults}
          />
        </Elements>
      </div>
    {/if}

    <div class="footer">
      <p>Your balance will only get depleted when your prospect responds. When your balance hits zero, your pitch link will expire.</p>

      <div class="actions">
        <a class="back" href="/app/campaign-links" use:inertia>Cancel</a>
        <Button
          type="submit"
          on:submit={(event) => handleSubmit(event)}
          loading={processing}
          disabled={!stripe || processing}>Activate Campaign</Button>
      </div>
    </div>
  </form>
{/if}

<style lang="postcss">
  .elements {
    @apply px-10 pb-8 mt-8;
  }

  .cards {
    @apply px-10 py-8 flex gap-4 flex-col;

    label {
      @apply flex gap-4;
    }
  }

  .actions {
    @apply flex justify-between;
  }

  .footer {
    @apply px-10 py-4 bg-violet-100 border-t border-violet-300 text-right;

    p {
      @apply text-sm text-left;
    }
  }
</style>
