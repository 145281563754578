<script>
  import Layout from "@/Layouts/App.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import { page, inertia } from "@inertiajs/svelte";
  import ButtonGroup from "@/Components/Buttons/ButtonGroup.svelte";

  console.log($page.props?.attendees);
</script>

<Layout>
  <Seo title={$page.props?.conference?.name} />

  <section>
    <div class="flex flex-col sm:flex-row justify-between items-center py-2.5 px-4 lg:px-8 bg-blue-100 gap-2 sm:gap-4">
      <h1 class="text-xl leading-none sm:leading-normal">Events</h1>
    </div>

    <div class="px-4 sm:px-6 lg:px-8 mt-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h2 class="text-lg leading-none sm:leading-normal">{$page.props?.conference?.name}</h2>
          <p class="mt-2 text-sm text-gray-700">{$page.props?.conference?.description}</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <ButtonGroup size="sm">
            <Button href={`/app/events/${$page.props?.conference?.id}/edit`} size="xs" dynamic>Edit Event</Button>
            <Button href={`/app/events/${$page.props?.conference?.id}/upload`} size="xs" dynamic>Upload Attendees</Button>
          </ButtonGroup>
        </div>
      </div>

      <div class="flex gap-8">
        <div><strong>Users:</strong> {$page.props?.conference?.user_total}</div>
        <div><strong>Profiles:</strong> {$page.props?.conference?.profile_total}</div>
        <div><strong>Total:</strong> {$page.props?.conference?.attendee_total}</div>
      </div>
    </div>

    <div class="mt-8 flow-root overflow-hidden">
      <div class="px-4 sm:px-6 lg:px-8">
        <table class="w-full text-left">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                Name
                <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200"></div>
                <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200"></div>
              </th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Email</th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">LinkedIn</th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Location</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Organisation</th>
            </tr>
          </thead>
          <tbody>
          {#if !$page.props?.attendees?.data.length}
            <tr>
              <td class="px-3 py-4 text-sm text-gray-500" colspan="5">No attendees found.</td>
            </tr>
          {:else}
            {#each $page.props?.attendees?.data as attendee}
              <tr>
                <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
                  <a href={`/@${attendee?.slug}`} use:inertia>{attendee?.name}</a>
                  <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100"></div>
                  <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100"></div>
                </td>
                <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{attendee?.email}</td>
                <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{attendee?.linkedin_url}</td>
                <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{attendee?.country}</td>
                <td class="px-3 py-4 text-sm text-gray-500">
                  <a href={`/org/${attendee?.org_slug}`} use:inertia>{attendee?.org_name}</a>
                </td>
              </tr>
            {/each}
          {/if}
          </tbody>
        </table>

        {#if $page.props?.attendees?.links}
          <nav class="flex items-center justify-between border-t border-gray-200 bg-white py-3 mb-20" aria-label="Pagination">
            <div class="hidden sm:block">
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{$page.props?.attendees?.meta?.from ?? 0}</span>
                to
                <span class="font-medium">{$page.props?.attendees?.meta?.to ?? 0}</span>
                of
                <span class="font-medium">{$page.props?.attendees?.meta?.total ?? 0}</span>
                results
              </p>
            </div>
            <div class="flex flex-1 justify-between sm:justify-end">
              <ButtonGroup size="xs">
                <Button dynamic href={$page.props?.attendees?.links?.prev} disabled={$page.props?.attendees?.meta.current_page === 1}>Previous</Button>
                <Button dynamic href={$page.props?.attendees?.links?.next} disabled={$page.props?.attendees?.meta.current_page === $page.props?.attendees?.meta.last_page}>Next</Button>
              </ButtonGroup>
            </div>
          </nav>
        {/if}
      </div>
    </div>
  </section>
</Layout>
