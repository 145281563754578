<script>
import {page, inertia} from "@inertiajs/svelte";
import Button from "@/Components/Buttons/Button.svelte";
import Textarea from "@/Components/Forms/Textarea.svelte";

export let form;

export let callback = () => {};
</script>

<div class="pitch">
  <div class="head">
    <div>
      <div class="step active">1</div>
      <p>Write your pitch</p>
    </div>

    <span class="ellipsis"></span>

    <div>
      <div class="step">2</div>
      <p>Make an offer</p>
    </div>

    <span class="ellipsis"></span>
    <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
  </div>

  <form method="post" on:submit|preventDefault={(event) => callback(event)}>
    <Textarea
      name="pitch"
      inputClass="h-72 !text-sm !rounded-lg"
      maxLength="1000"
      placeholder="Enter your pitch here"
      bind:value={$form.body}
      error={$form.errors.body}
      errorClass="!text-white"
      rows="8"
    />

    <div class="flex justify-end mt-4">
      <div class="next">
        <Button
          type="submit"
          size="sm"
          color="red"
          loading={$form.processing}
          on:submit={(event) => callback(event)}
        >
          Continue
        </Button>
      </div>
    </div>
  </form>

  <div class="step-indicator">
    <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
       class="dot active" use:inertia={{preserveScroll: true}}
    >
    </a>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</div>


<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;

        &.active {
          @apply bg-red-500;
        }
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }

    .step-indicator {
      @apply flex justify-center items-center my-4;

      .dot {
        @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

        &.active {
          @apply bg-red-500;
        }
      }
    }
  }
</style>
