<script>
  import { page, useForm } from "@inertiajs/svelte";
  import Layout from "@/Layouts/Settings.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  let form = useForm({
    notifications: $page.props?.preferences?.notifications ?? {
      lead_rank_digest: false,
      pay_per_email_digest: false,
    }
  });

  function handleSave(event) {
    event.preventDefault();
    $form.post("/app/settings/subscriptions/save");
  }
</script>

<Seo title="Subscriptions" />

<Layout title="Subscriptions">
  <form method="post" on:submit={handleSave}>
    <div class="table-wrap">
      <table>
        <thead>
          <tr>
            <th scope="col">Notifications</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {#if $page.props?.auth?.user?.plan && $page.props?.auth?.user?.plan === $page.props?.per_email}
            <tr>
              <td>
                <h3>Pay per email digest</h3>
              </td>
              <td>
                <input
                  type="checkbox"
                  bind:value={$form.notifications.pay_per_email_digest}
                  bind:checked={$form.notifications.pay_per_email_digest}
                />
              </td>
            </tr>
          {/if}

          <tr>
            <td>
              <h3>Lead rank digest</h3>
            </td>
            <td>
              <input
                type="checkbox"
                bind:value={$form.notifications.lead_rank_digest}
                bind:checked={$form.notifications.lead_rank_digest}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="actions">
      <Button size="sm" on:click={handleSave}>Update Preferences</Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  .table-wrap {
    @apply inline-block min-w-full py-2 align-middle overflow-x-auto;
  }

  table {
    @apply min-w-full divide-y-2 divide-grey-200;
  }

  th,
  td {
    @apply py-3 px-6 text-left;
  }

  th {
    @apply bg-blue-50 text-xs font-bold uppercase;
  }

  td {
    @apply text-sm;
  }

  h3 {
    @apply text-sm;
  }

  .actions {
    @apply mt-8 flex justify-end;
  }
</style>
