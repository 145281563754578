export const getAuthUrl = (authUrl, redirectBack = false) => {
  let data = {};

  const excluded_urls = [
    '/revert-masquerade', '/logout',
    '/auth/google/authorise', '/auth/google/revoke', '/auth/google/login', '/auth/google/redirect',
    '/auth/microsoft/authorise', '/auth/microsoft/revoke', '/auth/microsoft/login', '/auth/microsoft/redirect',
    '/auth/linkedin/authorise', '/auth/linkedin/revoke', '/auth/linkedin/login', '/auth/linkedin/redirect',
    '/auth/login', '/auth/register', '/auth/verify-token'
  ];

  if (location) {
    if (redirectBack) {
      const path = new URL(location).pathname;
      data = excluded_urls.includes(path) ? {} : {
        redirect: path
      }
    }

    const urlParams = new URL(location).searchParams;

    if (urlParams.has('redirect')) {
      data.redirect = '/'+urlParams.get('redirect');
    }

    if (urlParams.has('referrer')) {
      data.referrer = '/'+urlParams.get('referrer');
    }

    if (urlParams.has('utm_medium')) {
      data.utm_medium = urlParams.get('utm_medium');
    }

    if (urlParams.has('utm_source')) {
      data.utm_source = urlParams.get('utm_source');
    }

    if (urlParams.has('utm_campaign')) {
      data.utm_campaign = urlParams.get('utm_campaign');
    }

    if (urlParams.has('utm_term')) {
      data.utm_term = urlParams.get('utm_term');
    }

    if (urlParams.has('utm_content')) {
      data.utm_content = urlParams.get('utm_content');
    }
  }

  let query = new URLSearchParams(data);

  let separator = authUrl.includes('?') ? '&' : '?';
  return query.toString() ? authUrl + separator + query.toString() : authUrl;
}
