const facebookPageName = 'thepitchfire';
const facebookAuthorPageName = '';

const website = {
  author: `Ryan O'Hara`,
  ogLanguage: 'en_US',
  siteLanguage: 'en-US',
  siteTitle: 'Pitchfire',
  siteShortTitle: 'Pitchfire',
  entity: 'Pitchfire',
  entityLegal: 'Request for Meeting, Inc.',
  description:
    'Less cold emails, more income. Cold calls and cold emails suck. Pitchfire lets you get paid to answer sellers, making everyone happy.',
  backgroundColor: '#ffffff',
  logo: '/assets/logo.png',
  themeColor: '#ffffff',
  siteUrl: 'https://pitchfire.com',
  contactEmail: 'support@pitchfire.com',
  facebookAuthorPage: facebookAuthorPageName && `https://www.facebook.com/${facebookAuthorPageName}`,
  facebookAuthorPageName,
  facebookPage: facebookPageName && `https://www.facebook.com/${facebookPageName}`,
  facebookPageName,
  instagramProfile: 'https://www.instagram.com/thepitchfire',
  linkedinProfile: 'https://www.linkedin.com/company/thepitchfire/',
  twitterUsername: 'thepitchfire',
  twitterUserId: '1412528122961006594'
};

export {website as default};
