<script>
  import Layout from "@/Layouts/CampaignLinks.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Range from "@/Components/Forms/Range.svelte";
  import CKEditor from "@/Components/Forms/CKEditor.svelte";

  let processing = false;

  let form = useForm({
    title: $page.props?.campaign_link?.title,
    amount: $page.props?.campaign_link?.amount,
    spend_limit: $page.props?.campaign_link?.spend_limit,
    pitch_body: $page.props?.campaign_link?.pitch,
  });

  const handleSave = () => {
    $form.put(`/app/campaign-links/${$page.props?.campaign_link?.id}/store`);
  };

  const handleDelete = () => {
    $form.post(`/app/campaign-links/${$page.props?.campaign_link?.id}/delete`);
  };
</script>

<Seo title={`Edit Campaign: ${$form.title}`} />

<Layout>
  <form method="post" on:submit|preventDefault={handleSave}>
    <div>
      <Input
        name="title"
        size="sm"
        label="Campaign Name"
        required
        placeholder="Enter your campaign name"
        error={$form.errors.title}
        bind:value={$form.title}
      />
    </div>

    <div>
      <Range
        label="How much do vou want to offer your prospects to respond to your pitch?"
        description="Note: the more you offer, the more likely you are to get a response."
        name="amount"
        minLabel="$25"
        min="25"
        maxLabel="$500"
        max="500"
        valuePrefix="$"
        error={$form.errors.amount}
        bind:value={$form.amount}
      />
    </div>

    <div>
      <CKEditor
        name="pitch_body"
        size="sm"
        label="Write your Pitch"
        bind:value={$form.pitch_body}
        error={$form.errors.pitch_body}
        placeholder="Every buyer who clicks your link will see this pitch."
        required
        maxLength="1000"
        layout="full"
      />
    </div>

    <div class="actions">
      <Button type="button" color="tertiary" href="/app/campaign-links" dynamic>Cancel</Button>

      <div>
        <Button type="button" color="tertiary" on:click={(event) => handleDelete(event)}>Delete</Button>
        <Button type="submit" color="secondary" on:submit={(event) => handleSave(event)} disabled={$form.processing || processing}>
          {#if $page.props?.campaign_link?.status === 'Draft'}
            Next
          {:else}
            Save
          {/if}
        </Button>
      </div>
    </div>
  </form>
</Layout>

<style lang="postcss">
  form {
    @apply flex flex-col gap-6 xl:w-3/4;

    .actions {
      @apply flex gap-4 items-center justify-between;

      div {
        @apply flex gap-4;
      }
    }
  }
</style>
