<script>
  import { useForm } from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Layout from "@/Layouts/Auth.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Turnstile from "@/Components/Forms/Turnstile.svelte";

  let verifyEmail = false;

  let form = useForm({
    email: '',
    cf_response: '',
  });

  const handleSubmit = async () => {
    $form.post('/app/onboarding/email/save', {
      preserveScroll: true,
      onSuccess: () => {
        $form.reset();
        verifyEmail = true;
      },
    });
  };
</script>

<Seo title="Tell us about yourself" />

<Layout title="Tell us about yourself" page={2}>
  {#if verifyEmail}
    <p class="success">We've sent you an email to verify your email address. Please check your inbox and click the link to continue.</p>
  {:else}
    <form method="post" on:submit|preventDefault={handleSubmit}>
      <div>
        <label for="email">Work Email <sup>*</sup></label>
        <Input
          inputClass="!bg-white !text-sm !rounded min-h-10"
          type="email"
          name="email"
          autocomplete="email"
          bind:value={$form.email}
          placeholder="Enter a work email address"
          error={$form.errors.email}
          required
          size="sm"
        />

        <Turnstile action="work_email" bind:response={$form.cf_response} bind:error={$form.errors.cf_response} />
      </div>

      <Button
        type="submit"
        size="sm"
        class="max-w-28"
        loading={$form.processing}
        on:click={handleSubmit}>Next</Button>
    </form>
  {/if}
</Layout>

<style lang="postcss">
  form {
    @apply flex justify-between flex-col;

    & > div {
      @apply mb-4;
    }

    label {
      @apply text-xs uppercase;

      sup {
        @apply text-red-600 text-sm;
      }
    }
  }

  .success {
    @apply text-green-700 text-sm border border-green-800 rounded p-2 bg-green-50;
  }
</style>
