<script>
  import Layout from "@/Layouts/Page.svelte"
  import Card from "@/Components/Layout/Card.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page} from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  export let status;

  $: title = {
    401: 'Unauthorized',
    403: '403: Forbidden',
    404: '404: Page Not Found',
    419: 'Page Expired',
    429: 'Too Many Requests',
    500: '500: Server Error',
    503: 'Site under maintenance',
  }[status]

  $: description = {
    401: 'You access to this area is not authorized.',
    403: 'You are forbidden from accessing this page.',
    404: 'The page you are looking for could not be found.',
    419: 'The page you are trying to access has expired, please refresh and try again.',
    429: 'We have received too many requests from you, please try again later.',
    500: 'Whoops, something went wrong.',
    503: 'We are doing some maintenance. Please check back soon.',
  }[status]
</script>

<Seo {title} />

<Layout nav footer>
  <main>
    <Card {title} class="max-w-3xl mx-auto text-center">
      <p>{description}</p>

      {#if status === 404 || status === 403}
        <p>Navigate to the home page to login or search our buyer marketplace.</p>

        {#if $page.props?.auth.loggedIn}
          <Button href="/app">Go to home page</Button>
          <Button color="secondary" href="/app/marketplace">Go to marketplace</Button>
        {:else}
          <Button href="/">Go to home page</Button>
          <Button color="secondary" href="/marketplace">Go to marketplace</Button>
        {/if}
      {/if}
    </Card>
  </main>
</Layout>

<style lang="postcss">
  main {
    @apply mx-auto px-4 py-8 container h-full max-w-7xl;
  }
</style>
