<script>
  import classNames from 'classnames';

  export let defaultTags = [];
  export let suggestionTags = []; // Assuming suggestionTags is an array of objects
  export let label = "";
  export let labelHtml = "";

  /** @type {string} name */
  export let name;

  /** @type {string} inputClass */
  export let inputClass = '';

  export let classes = '';

  export let size = 'md';

  /** @type {boolean} isTag */
  export let isTag = false;

  /** @type {string | number} value */
  export let value = "";

  /** @type {string | number} value */
  export let placeholder = "";


  let inputValue = "";
  let suggestions = [];

  let tags = defaultTags.slice();

  // Function to filter suggestions based on input value
  function filterSuggestions() {
    suggestions = suggestionTags.filter(
      (tag) =>
        tag.label.toLowerCase().includes(inputValue.toLowerCase()) &&
        !tags.find((t) => t.label === tag.label),
    );
  }

  // Function to add tag to the list
  function addTag(tag) {
    if (!tags.find((t) => t.label === tag.label)) {
      tags = [...tags, tag];
      inputValue = '';
    }
    updateValue();
  }

  // Function to remove tag from the list
  function removeTag(event, tagToRemove) {
    event.preventDefault();
    tags = tags.filter((tag) => tag.label !== tagToRemove.label);
    updateValue();
  }

  // Function to handle tag selection from suggestions
  function selectTag(tag) {
    addTag(tag);
    inputValue = ""; // Clear the input field after adding the tag
  }

  // Handle keyboard events for selecting suggestions
  function handleKeyDown(event, tag) {
    if (event.key === "Enter" || event.key === " ") {
      selectTag(tag);
    }
  }

  // Update the value to reflect the changes in tags
  function updateValue() {
    value = tags;
  }
</script>

<div class={classNames('input', size, classes)}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name}>{label}{@html labelHtml}</label>
  {/if}

  <div>
    {#if suggestionTags && inputValue}
      <ul>
        {#each suggestions as suggestion}
          <li>
            <button on:click|preventDefault={() => selectTag(suggestion)} on:keydown={(e) => handleKeyDown(e, suggestion)}>
              {suggestion.label}
            </button>
          </li>
        {/each}
      </ul>
    {/if}

    <input
      {...$$restProps}
      class={inputClass}
      type="text"
      bind:value={inputValue}
      {name}
      {placeholder}
      on:keydown={(e) => {
        filterSuggestions();
        if (e.key === "Enter") {
          e.preventDefault(); // Prevent form submission
          if (suggestions.length > 0) {
            selectTag(suggestions[0]); // Select the first suggestion
          } else if (inputValue.trim() !== "" && isTag) {
            addTag({ label: inputValue }); // Add tag based on input value if no suggestion is selected
          }
        }
      }}
    />
  </div>

  <div class="list">
    {#each tags as tag}
      <span class="tag">
        <span>{tag.label}</span>
        <button on:click={(e) => removeTag(e, tag)}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C9.318 0 12 2.682 12 6C12 9.318 9.318 12 6 12C2.682 12 0 9.318 0 6C0 2.682 2.682 0 6 0ZM8.154 3L6 5.154L3.846 3L3 3.846L5.154 6L3 8.154L3.846 9L6 6.846L8.154 9L9 8.154L6.846 6L9 3.846L8.154 3Z" fill="#D81E5B" />
          </svg>
        </button>
      </span>
    {/each}
  </div>
</div>

<style lang="postcss">
  .input {
    &.xs {
      input {
        @apply py-2 text-xs !leading-none;
      }
    }

    &.sm {
      input {
        @apply py-2 text-sm !leading-none;
      }
    }

    &.lg {
      input {
        @apply py-3.5 text-lg !leading-none;
      }
    }
  }

  ul {
    @apply p-[10px] border border-grey-200 m-0 border-b-0 rounded-t-sm list-none max-h-24 overflow-auto;

    li {
      @apply list-none pl-0 text-sm cursor-pointer;

      button {
        @apply appearance-none;
      }
    }
  }

  input {
    @apply border border-grey-200 rounded-lg px-2 py-3 text-grey-700 text-base !leading-none block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500;
  }

  .list {
    @apply flex gap-2 flex-wrap mt-2;
  }

  .tag {
    @apply text-xs py-1 px-1.5 border border-blue/20 rounded-md flex gap-1 items-center shadow bg-grey-100;

    svg {
      @apply cursor-pointer;
    }
  }
</style>
