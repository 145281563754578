<script>
  import Layout from "@/Layouts/Respond.svelte"

  import {page, inertia, useForm} from "@inertiajs/svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconPencil} from "@/Components/Assets/Icons/index.js";
  import Button from "@/Components/Buttons/Button.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import Label from "@/Components/Forms/Label.svelte";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import api from "@/Lib/api.js";
  import Flatpickr from 'svelte-flatpickr';
  import 'flatpickr/dist/flatpickr.css';
  import {failure} from "@/Lib/notices.js";
  import Seo from "@/Components/Seo/Index.svelte";

  let availableDates = $page.props?.slots;
  let availableTimes = [];
  let processing = false;

  let form = useForm({
    meeting_date: '',
    meeting_date_formatted: '',
    meeting_time: '',
    meeting_timezone: $page.props.buyer_timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const updateTimezone = async (timezone = '') => {
    processing = true;
    availableTimes = []
    availableDates = []

    if (timezone === '') {
      timezone = $form.meeting_timezone;
    }

    api.post(`/app/respond/p/${$page.props.pitch.id}/availability?timezone=${timezone}`)
      .then((res) => res.data)
      .then((res) => {
        processing = false;
        availableDates = res.slots

        if ($form.meeting_date && $form.meeting_date instanceof Date && !isNaN($form.meeting_date)) {
          let dateStr = $form.meeting_date.toISOString().split('T')[0];
          if (dateStr && dateStr in availableDates) {
            availableTimes = availableDates[dateStr];
          }
        }
      })
      .catch((e) => {
        processing = false;
        failure(e.message)
      });
  }

  const updateTimezoneEvent = async (event) => {
    await updateTimezone(event.detail.value);
  }

  const setTime = (time) => {
    $form.setStore('meeting_time', time);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    $form
      .transform(data => {
        const timezone = data.meeting_timezone.value;
        return {
          ...data,
          meeting_timezone: timezone
        };
      })
      .post(`/app/respond/p/${$page.props.pitch.id}/meeting/save`, {
        onError: () => {
          $form.reset()
          updateTimezone()
        }
      })
  }
</script>

<Seo title={`Book Meeting with ${$page.props?.pitch?.seller_name}`} />

<Layout>
  <div class="response">
    <div>
      <p><strong>I am interested in meeting with you and {$page.props?.pitch?.org_name} because...</strong></p>
      <p>{$page.props?.response_model?.body}</p>
    </div>
    <a class="edit" href="/app/respond/p/{$page.props?.pitch.id}/respond" use:inertia>
      <Icon data={IconPencil} class="fill-white mx-auto" />
    </a>
  </div>

  <form method="post" on:submit|preventDefault={(event) => handleSubmit(event)}>
    <p><strong>Let’s setup a time with {$page.props?.pitch?.seller_name}. These are times you are both available.</strong></p>
    <div class="relative z-10">
      <SelectBox
        id="timezone"
        name="timezone"
        label="Time Zone"
        size="sm"
        multiple={false}
        placeholder="Select Timezone"
        options={$page.props?.timezones}
        bind:value={$form.meeting_timezone}
        error={$form.errors.meeting_timezone}
        on:change={(event) => updateTimezoneEvent(event)}
        on:clear={(event) => updateTimezoneEvent(event)}
      />
    </div>

    {#if $form.errors.meeting_date}
      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_date}</div>
    {/if}
    {#if $form.errors.meeting_time}
      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_time}</div>
    {/if}

    <div class="form-row">
      <div>
        <Label name="date" size="sm" label="Select Date" />
        <Flatpickr
          name="date"
          bind:value={$form.meeting_date}
          bind:formattedValue={$form.meeting_date_formatted}
          options={{
            inline: true,
            enable: Object.keys(availableDates),
            altInputClass: 'hidden',
            altInput: true,
            onChange(selectedDates, dateStr) {
              availableTimes = availableDates[dateStr]
            }
          }}
        />
      </div>

      <div class="times">
        <Label name="time" size="sm" label="Select a time" />
        {#if $form.meeting_date}
          <div>
            {#each availableTimes as time}
              <Button
                type="button"
                size="sm"
                color="tertiary"
                class="relative"
                disabled={$form.processing || processing}
                on:click={() => setTime(time)}
              >
                {#if time === $form.meeting_time}
                  <span class="bull"></span>
                {/if}
                <span>{time}</span>
              </Button>
            {:else}
              <p>No time slots available</p>
            {/each}
          </div>
        {:else}
          <p>Please select a date</p>
        {/if}

        {#if $form.processing || processing}
          <Loader />
        {/if}
      </div>
    </div>
    <div class="next">
      <Button
        type="submit"
        color="secondary"
        disabled={$form.processing || processing}
        on:submit={(event) => handleSubmit(event)}
      >Done
      </Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  .response {
    @apply text-sm px-4 py-3 mb-4 border border-violet-400 bg-violet-50 rounded-xl flex justify-between gap-4 items-center;

    div {
      @apply flex flex-col gap-1.5;
    }

    p {
      @apply m-0 text-xs;
    }

    a {
      @apply bg-violet flex rounded-full w-10 h-10 leading-none items-center justify-center hover:bg-violet-600 focus:ring focus:ring-violet-600;
    }
  }

  form {
    @apply flex flex-col gap-4;

    & > p {
      @apply text-sm mb-0;
    }

    .form-row {
      @apply flex flex-row flex-wrap gap-4 w-full;
    }

    .times {
      @apply block grow;

      div {
        @apply grid grid-cols-2 gap-x-4 gap-y-2;
      }
    }

    .next {
      @apply mt-4 flex justify-end gap-4 mb-20;
    }
  }

  .bull {
    @apply absolute before:content-["*"] left-1.5 top-1;
  }
</style>
