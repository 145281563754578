<script>
  import Layout from "@/Layouts/Auth.svelte";
  import { useForm, page } from '@inertiajs/svelte';
  import Seo from "@/Components/Seo/Index.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  let form = useForm({
    first_name: $page.auth?.user?.first_name ?? '',
    last_name: $page.auth?.user?.last_name ?? '',
  });


  const handleSubmit = async () => {
    $form.transform(data => {
      return {
        ...data,
        name: data.first_name + ' ' + data.last_name,
      };
    }).post('/app/onboarding/name/save');
  };

</script>

<Seo title="Tell us about yourself" />

<Layout title="Tell us about yourself" page={2}>
  <form on:submit|preventDefault={(e) => handleSubmit(e)}>
    <div>
      <label for="first_name">First Name <sup>*</sup></label>
      <Input
        inputClass="!bg-white !text-sm !rounded min-h-10"
        type="text"
        name="first_name"
        autocomplete="given-name"
        bind:value={$form.first_name}
        placeholder="Enter your first name"
        error={$form.errors.first_name}
        required
        size="sm"
      />
    </div>

    <div>
      <label for="last_name">Last Name <sup>*</sup></label>
      <Input
        inputClass="!bg-white !text-sm !rounded min-h-10"
        type="text"
        name="last_name"
        autocomplete="family-name"
        bind:value={$form.last_name}
        placeholder="Enter your last name"
        error={$form.errors.last_name}
        required
        size="sm"
      />
    </div>

    <Button
      type="submit"
      size="sm"
      class="max-w-28"
      loading={$form.processing}
      on:click={handleSubmit}>Next</Button>
  </form>
</Layout>

<style lang="postcss">
  form {
    @apply flex justify-between flex-col;

    & > div {
      @apply mb-4;
    }

    label {
      @apply text-xs uppercase;

      sup {
        @apply text-red-600 text-sm;
      }
    }
  }
</style>
