<script>
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Layout from "@/Layouts/App.svelte";
  import {page} from "@inertiajs/svelte";
  import MenuItem from "@/Components/Layout/MenuItem.svelte";

  import {
    IconBolt, IconDollar, IconPlug, IconUser, IconCreditCard, //IconEnvelope,
  } from "@/Components/Assets/Icons";

  export let title = '';
  export let crumb = '';

  $: menu = [
    { name: 'Plugins', url: '/app/settings/plugins', icon: IconPlug },
    { name: 'Integrations', url: '/app/settings/integrations', icon: IconBolt },
    // { name: 'Subscriptions', url: '/app/settings/subscriptions', icon: IconEnvelope, adminOnly: true },
    { name: 'Payment Methods', url: '/app/settings/payment-methods', icon: IconCreditCard },
    { name: 'Credits', url: '/app/settings/credits', icon: IconDollar },
    { name: 'Account', url: '/app/settings/account', icon: IconUser },
  ];

  const full_url = $page.props.common.app_url + $page.url;
  const activeMenu = (uri) => {
    return full_url === uri || $page.url === uri || $page.url?.startsWith(uri) || full_url?.startsWith(uri);
  }
</script>

<Layout>
  <div class="wrap">
    <aside>
      <h1>Settings</h1>

      <div>
        <Breadcrumb crumbs={[
          { title: "Menu", url: "/app" },
          { title: "Settings", url: "/app/settings" },
          { title: crumb ? crumb : title }
        ]} />

        <nav>
          <ul>
            {#each menu as item}
              <li>
                <MenuItem
                  active={activeMenu(item.url)}
                  title={item.name}
                  icon={item.icon}
                  href={item.url}
                  adminOnly={item.adminOnly}
                  theme="light"
                />
              </li>
            {/each}
          </ul>
        </nav>
      </div>
    </aside>

    <div class="content">
      <h2>{title}</h2>

      <slot />
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply flex-1 md:flex min-h-screen;
  }

  aside {
    @apply md:w-80 md:shrink-0 py-8 lg:py-6 bg-grey-100;

    & > div {
      @apply mx-4;

      h2 {
        @apply text-lg md:text-xl mt-8;
      }
    }
  }

  .content {
    @apply w-full md:border-l border-grey-200 px-6 py-8 lg:py-6 flex flex-col md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-90px)] lg:max-h-screen;
  }

  h1 {
    @apply text-3xl md:text-4xl mb-8 ml-4;
  }

  h2 {
    @apply text-2xl md:text-3xl mb-8;
  }

  nav {
    @apply mt-8 flex flex-col;

    ul {
      @apply p-0;

      li {
        @apply list-none p-0;
      }
    }
  }
</style>
