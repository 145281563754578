<script>
  import Layout from '@/Layouts/User.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import PitchRate from "@/Components/Sections/Pitch/SetRate.svelte";

  let form = useForm({
    buyer_id: $page.props?.pitch?.buyer_id,
    request_fee: $page.props?.pitch?.request_fee,
    pitch_id: $page.props?.pitch?.id,
    type : $page.props?.profile?.source === '5x5' ? 'profiles' : 'users'
  });

  const handleSubmit = () => {
    $form.post(`/pitch/${$page.props?.pitch?.id}/set-rate`, {
      preserveState: true,
      preserveScroll: true,
    })
  }
</script>

<Seo title={`Pitch ${$page.props.profile.name}`} />

<Layout profile={$page.props?.profile}>
  <PitchRate
    previous="/pitch/${$page.props?.pitch?.id}"
    callback={handleSubmit}
    {form}
  />
</Layout>
