<script>
  import Layout from "@/Layouts/Auth.svelte";
  import { useForm } from '@inertiajs/svelte';
  import Seo from "@/Components/Seo/Index.svelte";
  import {onMount} from "svelte";
  import Input from "@/Components/Forms/Input.svelte";

  let form = useForm({
    timezone: '',
    persona: 'both'
  });

  const handleSubmit = async () => {
    $form.post('/app/onboarding/persona/save');
  };

  onMount(() => {
    $form.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  })
</script>

<Seo title="Setup your account" />

<Layout title="Setup your account" page={2}>
  <form on:submit|preventDefault={(e) => handleSubmit(e)} class="flex justify-between flex-col mt-8">
    <fieldset>
      <p class="mt-1 text-sm leading-6 text-grey-600">
        <strong>What will you use Pitchfire for?</strong>
      </p>
      <div class="mt-6 space-y-2">
        <div class="flex items-center">
          <input id="buyer" name="persona" type="radio" value="Buyer" bind:group={$form.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="buyer" class="ml-3 block text-sm font-medium leading-6 text-grey-900">Get way less emails</label>
        </div>
        <div class="flex items-center">
          <input id="seller" name="persona" type="radio" value="Seller" bind:group={$form.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="seller" class="ml-3 block text-sm font-medium leading-6 text-grey-900">Actually get responses from prospects</label>
        </div>
        <div class="flex items-center">
          <input id="both" name="persona" type="radio" value="Both" bind:group={$form.persona} class="h-4 w-4 border-grey-300 text-indigo-600 focus:ring-indigo-600">
          <label for="both" class="ml-3 block text-sm font-medium leading-6 text-grey-900">I want all the things!</label>
        </div>
      </div>
    </fieldset>

    {#if $form.errors.persona}
      <p class="m-0 mt-1 text-red-600 leading-snug text-xs">{$form.errors.persona}</p>
    {/if}

    <Input type="hidden" id="timezone" name="timezone" bind:value={$form.timezone} />

    <div class="text-start">
      <button
        type="submit"
        on:click|preventDefault={(e) => handleSubmit(e)}
        class="text-sm !bg-blue-700 !text-white	border p-2.5 mt-8 rounded-lg font-medium w-full max-w-28">Next</button>
    </div>
  </form>
</Layout>
