<script>
  import Icon from "@/Components/Assets/Icon.svelte";
  import { IconPencil, IconAvatar } from "@/Components/Assets/Icons";
  import { failure } from "@/Lib/notices";
  import classNames from "classnames";

  const fileTypes = ["image/jpeg", "image/png"];

  export let name;

  /** @type {string | ArrayBuffer} value */
  export let value;

  /** @type {string | ArrayBuffer} preview */
  export let preview = "/assets/avatar.png";

  let uploaded;

  const updatePreview = (event) => {
    const file = event.target.files[0];

    if (!validFileType(file)) {
      failure('Invalid file type, only ".png" or ".jpg" images allowed');
      return;
    }

    if (file.size > 512000) {
      failure("File size too large, 512Kb max");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      uploaded = e.target?.result;
      value = uploaded;
    };
  };

  const validFileType = (file) => {
    return fileTypes.includes(file.type);
  };
</script>

<div {...$$restProps} class={classNames("upload", $$props.class)}>
  {#if uploaded}
    <img src={uploaded} alt="preview" />
  {:else if preview.includes('-fallback')}
    <div class="avatar-outer">
      <div>
        <Icon data={IconAvatar} size="xl" />
      </div>
      <p>Photos get featured and receive 9x requests.</p>
    </div>
  {:else}
    <img src={preview} alt="preview" />
  {/if}

  <div class="input">
    <Icon data={IconPencil} size="xl" />
  </div>
  <input
    accept="image/png, image/jpeg"
    {name}
    on:change={(event) => updatePreview(event)}
    type="file"
  />
</div>

<style lang="postcss">
  .upload {
    @apply relative;

    .avatar-outer {
      @apply border rounded-xl bg-blue-700 flex flex-col items-center text-center pt-2 px-1 w-32;

      p {
        @apply text-sm text-white font-medium;
      }
    }

    img {
      @apply rounded-xl max-w-32;
    }

    &:hover {
      .input {
        @apply opacity-100;
      }
    }

    .input {
      @apply opacity-0 transition-opacity absolute top-0 left-0 rounded-xl h-full w-full hover:bg-transparent bg-black/30 flex items-center justify-center;
    }

    input {
      @apply appearance-none w-full h-full border-0 absolute top-0 left-0 right-0 bottom-0 opacity-0 focus:outline-0 z-10 cursor-pointer;
    }
  }
</style>
