/** @param {string} svg */
function extractViewBox(svg) {
  const regex = /viewBox="([\d\- .]+)"/
  const res = regex.exec(svg)
  if (!res) return '0 0 24 24' // default value
  return res[1]
}

/** @param {string} svg */
function extractData(svg) {
  return svg.replace(/<svg[ \n]([^>]*)>/, '').replace('</svg>', '')
}

export {
  extractViewBox,
  extractData
}
