import {createInertiaApp} from '@inertiajs/svelte';

import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '../css/app.pcss';
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
});

createInertiaApp({
  progress: {
    color: '#EB534B',
    showSpinner: true,
    includeCSS: false,
  },
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.svelte', {eager: true})
    return pages[`./Pages/${name}.svelte`]
  },
  setup({el, App, props}) {
    new App({ target: el, hydrate: true, props })
  },
})
