<script>
  import classNames from 'classnames';

  /** @type {import('@/Lib/types/component.d').InputType} type */
  export let type = 'text';
  export let label = '';
  export let labelHtml = '';
  export let description = '';

  /** @type {string} name */
  export let name;

  /** @type {boolean} disabled */
  export let disabled = undefined;

  /** @type {string} inputClass */
  export let inputClass = undefined;

  /** @type {string} wrapperClass */
  export let wrapperClass = undefined;

  /** @type {string | number} value */
  export let value = '';
  export let loading = false;

  export let error = '';

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size = undefined;

  /**
   * @typedef {'top' | 'inline'} Position
   */

  /** @satisfies Position */
  export let labelPosition = 'top';

  // you need to this to avoid 2-way binding
  /**
   * @param {HTMLInputElement} node
   * @param {string} _type
   */
  const setType = (node, _type) => {
    node.type = _type;
    return {
      update(_type) {
        node.type = _type;
      }
    };
  };
</script>

<div class={classNames('input', size, `label-${labelPosition}`, `type-${type}`)}>
  {#if label || labelHtml}
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    <label for={name}>{label}{@html labelHtml}</label>
  {/if}

  <div class={wrapperClass}>
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}
    <slot props={{ ...$$restProps, class: inputClass, disabled: disabled || loading, name: name }}>
      <input
        {...$$restProps}
        bind:value
        class={inputClass}
        disabled={disabled || loading}
        {name}
        on:blur
        on:change
        on:click
        on:contextmenu
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        on:mouseenter
        on:mouseleave
        on:mouseover
        on:paste
        use:setType={type} />
      {#if loading}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      {/if}
    </slot>
    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
  </div>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if description}
    <p class="desc">{description}</p>
  {/if}
</div>

<style lang="postcss">
  .input {
    label {
      @apply w-full block text-base mb-1 text-grey-700;
    }

    & > div {
      @apply relative w-full items-center inline-flex;
    }

    .left {
      @apply left-0 pr-2.5 pointer-events-none;
    }

    .right {
      @apply right-0 pl-2.5;
    }

    input {
      @apply border border-grey-200 rounded-lg px-2 py-3 text-grey-700 text-base !leading-none block w-full disabled:cursor-not-allowed disabled:opacity-50 focus:border-grey-500 focus:ring-grey-500 focus-visible:outline-0 placeholder:text-grey-500;
    }

    svg {
      @apply block w-4 h-4 animate-spin absolute z-10 right-4;
    }

    &.xs {
      label {
        @apply text-xs;
      }

      input {
        @apply py-2 text-xs !leading-none;
      }
    }

    &.sm {
      label {
        @apply text-sm;
      }

      input {
        @apply py-2 text-sm !leading-none;
      }
    }

    &.lg {
      label {
        @apply text-lg;
      }

      input {
        @apply py-3.5 text-lg !leading-none;
      }
    }

    &.error {
      @apply border border-red-400;
    }

    &.disabled {
      label {
        @apply opacity-50;
      }
    }

    &.label-inline {
      @apply md:flex gap-4;

      label {
        @apply w-auto whitespace-nowrap;
      }
    }
  }

  .type-checkbox {
    @apply flex items-center gap-2;

    & > div {
      @apply w-auto;
    }

    label {
      @apply text-left w-auto order-2 mb-0 !leading-tight;
    }

    input {
      @apply border-0 w-auto;
    }
  }

  p.error {
    @apply m-0 mt-1 text-red-600 leading-snug text-xs;
  }

  p.desc {
    @apply m-0 mt-2 text-grey-600 leading-snug text-xs;
  }
</style>
