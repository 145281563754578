<script>
  import Layout from '@/Layouts/User.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import PitchBody from "@/Components/Sections/Pitch/Body.svelte";
  import {page, useForm} from "@inertiajs/svelte";

  let form = useForm({
    buyer_id: $page.props?.profile?.id,
    body: $page.props?.pitch?.body,
    meeting_link: $page.props?.pitch?.meeting_link,
    pitch_id: $page.props?.pitch?.id,
    type : $page.props?.profile?.source === '5x5' ? 'profiles' : 'users'
  });

  const handleSubmit = () => {
    $form.post('/pitch', {
      preserveState: true,
      preserveScroll: true,
    })
  }
</script>

<Seo
  title={`Pitch ${$page.props.profile.name}`}
  breadcrumbs={[
    { name: 'Marketplace', slug: '/marketplace' }
  ]}
/>

<Layout profile={$page.props?.profile}>
  <PitchBody callback={handleSubmit} {form} />
</Layout>
