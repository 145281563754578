<script>
import Layout from "@/Layouts/App.svelte";
import Seo from "@/Components/Seo/Index.svelte";
import Button from "@/Components/Buttons/Button.svelte";
import { page, inertia } from "@inertiajs/svelte";

console.log($page.props?.attendees);
</script>

<Layout>
  <Seo title="Events" />

  <section>
    <div class="flex flex-col sm:flex-row justify-between items-center py-2.5 px-4 lg:px-8 bg-blue-100 gap-2 sm:gap-4">
      <h1 class="text-xl leading-none sm:leading-normal">Events</h1>
    </div>

    <div class="px-4 sm:px-6 lg:px-8 mt-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <p class="mt-2 text-sm text-gray-700">A list of all the conferences with tailored marketplaces.</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button href="/app/events/create" size="sm" dynamic>Create Event</Button>
        </div>
      </div>
    </div>

    <div class="mt-8 flow-root overflow-hidden">
      <div class="px-4 sm:px-6 lg:px-8">
        <table class="w-full text-left">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                Name
                <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200"></div>
                <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200"></div>
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"></th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Users</th>
              <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Profiles</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
              <th scope="col" class="relative py-3.5 pl-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {#if !$page.props?.conferences?.data.length}
              <tr>
                <td class="px-3 py-4 text-sm text-gray-500" colspan="5">No events found.</td>
              </tr>
            {:else}
              {#each $page.props?.conferences?.data as event}
                <tr>
                  <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    <a href={`/app/events/${event?.id}`} use:inertia>{event?.name}</a>
                    <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100"></div>
                    <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100"></div>
                  </td>
                  <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    <a href={`/app/events/${event?.slug}/marketplace`} use:inertia>Marketplace</a>
                  </td>
                  <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{event?.user_total}</td>
                  <td class="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{event?.profile_total}</td>
                  <td class="px-3 py-4 text-sm text-gray-500">{event?.attendee_total}</td>
                  <td class="relative py-4 pl-3 text-right text-sm font-medium">
                    <a href={`/app/events/${event?.id}/edit`} class="text-blue-600 hover:text-indigo-900">Edit</a>
                  </td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </table>
      </div>
    </div>
  </section>
</Layout>
