<script>
  import Alert from "@/Components/Elements/Alert.svelte";
  import Layout from "@/Layouts/Settings.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import PaymentCard from "@/Components/Shop/Card.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, router} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {PaymentElement, Elements, Address} from "svelte-stripe";
  import { loadStripe } from "@stripe/stripe-js";
  import { onMount } from "svelte";
  import api from "@/Lib/api";
  import { failure } from "@/Lib/notices";
  export let stripe_key;

  let client_secret;
  let new_method;
  let elements;
  let defaults = {};
  let stripe;
  let processing = false;
  let loading = false;

  const addNewMethod = () => {
    loading = true;

    api
      .get(`/app/settings/payment-methods/create`)
      .then((res) => {
        client_secret = res.data?.client_secret;
        defaults = res.data?.defaults;
        new_method = true;
        loading = false;
        processing = true;
      })
      .catch((e) => {
        failure(e.response?.message ?? e.response?.data?.error ?? 'An unknown error occurred');
        new_method = false;
        loading = false;
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (processing) return;

    processing = true;
    if (new_method) {
      const result = await stripe
        .confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url: `${$page.props.common.app_url}/app/settings/payment-methods`,
          },
        });

      if (result.error) {
        failure(result.error?.message ?? 'An unknown error occurred');
        processing = false;
      } else {
        new_method = false;
        router.reload();
      }
    }
  };

  onMount(async () => {
    stripe = await loadStripe(stripe_key);
  });
</script>

<Seo title="Payment Methods" />

<Layout title="Payment Methods">
  <div class="cards">
    <p>We use encrypted methods to save your card details securely</p>

    {#if $page.props?.payment_methods.length > 0}
      <div class="methods">
        {#each $page.props?.payment_methods as method}
          <PaymentCard
            key={method.id}
            id={method.id}
            brand={method.brand}
            brand_formatted={method.brand_formatted}
            expiry={method.expiry}
            last4={method.last4}
            name={method.name}
            address={method?.address}
            default_method={method.default_method}
            expired={method.expired}
          />
        {:else}
          <Loader />
        {/each}
      </div>
    {:else}
      <Alert type="info" title="No payment methods found" />
    {/if}

    <div class="add">
      {#if new_method}
        <div class="card-form">
          <h2>Add New Payment Method</h2>
          <p>Enter your card details below</p>
          <div class="elements">
            <Elements {stripe} clientSecret={client_secret} loader="always" bind:elements>
              <form on:submit={(e) => handleSubmit(e)}>
                <PaymentElement on:ready={() => processing = false} />
                <Address
                  mode="billing"
                  defaultValues={defaults}
                />

                <Button
                  type="submit"
                  color="secondary"
                  class="mt-4"
                  loading={processing}
                  on:click={(e) => handleSubmit(e)}>Save</Button>
              </form>
            </Elements>
          </div>
        </div>
      {:else}
        <Button on:click={addNewMethod} {loading}>Add Payment Method</Button>
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  .cards {
    @apply my-4 max-w-2xl;
  }

  .methods {
    @apply flex flex-col gap-4;
  }

  .add {
    @apply mt-6;
  }

  .card-form,
  .elements {
    @apply my-6
  }

  h2 {
    @apply text-xl font-semibold text-blue-800;
  }

  p {
    @apply text-sm;
  }
</style>
