<script>
  import {SvelteToast} from "@zerodevx/svelte-toast";
  import {success, info, warning, failure} from "@/Lib/notices";
  import {page} from "@inertiajs/svelte";
  import posthog from "posthog-js";
  import {onMount} from "svelte";
  import {meta} from "@/Lib/tracking.js";

  $: if ($page.props?.flash?.success) {
    success($page.props?.flash?.success)
  }

  $: if ($page.props?.flash?.warning) {
    warning($page.props?.flash?.warning)
  }

  $: if ($page.props?.flash?.error) {
    failure($page.props?.flash?.error)
  }

  $: if ($page.props?.flash?.info) {
    info($page.props?.flash?.info)
  }

  onMount(async () => {
    const posthog_host = import.meta.env.VITE_POSTHOG_HOST;
    const posthog_key = import.meta.env.VITE_POSTHOG_KEY;

    if (posthog_key && posthog_host) {
      posthog.init(posthog_key, {
        api_host: posthog_host,
        autocapture: true,
        loaded: (posthog) => {
          if ($page.props.auth?.user?.id) {
            posthog.identify($page.props.auth?.user.id, {
              email: $page.props.auth?.user.email,
              name: $page.props.auth?.user.name
            });
          }

          if ($page.props.common.app_env === 'local' || $page.props.common.app_env === 'development' || $page.props.common.app_env === 'staging') {
            posthog.debug()
          }
        }
      });
    }

    if ($page.props.common.app_env === 'production' && import.meta.env.VITE_META_KEY) {
      meta(import.meta.env.VITE_META_KEY);
    }
  });
</script>

<svelte:head>
  {#if $page.props.common.app_env === 'production' && import.meta.env.VITE_FIVEXFIVE_KEY}
    <script src="https://a.usbrowserspeed.com/cs?pid={import.meta.env.VITE_FIVEXFIVE_KEY}{$page.props.auth?.user?.id ? `&puid=${$page.props.auth?.user?.id}` : ''}{$page.props.auth?.hashed_id ? `&hem=${$page.props.auth?.hashed_id}` : ''}"></script>
  {/if}
</svelte:head>

<SvelteToast options={{ classes: ['notice'] }} />

<slot />

{#if $page.props.common.app_env === 'production' && import.meta.env.VITE_META_KEY}
  <noscript><img height="1" width="1" style:display="none" src="https://www.facebook.com/tr?id={import.meta.env.VITE_META_KEY}&ev=PageView&noscript=1" alt="" /></noscript>
{/if}

<style lang="postcss">
  :global(._toastContainer li) {
    @apply list-none m-0 p-0 text-sm leading-4;
    --toastMsgPadding: 0.375rem 0.675rem 0.675rem;
    --toastMinHeight: auto;
  }
</style>
