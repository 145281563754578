<script>
  import classNames from "classnames";
  import {extractData, extractViewBox} from "@/Lib/utils/svgData"
  import {page} from "@inertiajs/svelte";

  export let title;

  export let href;
  export let icon = undefined;
  export let active = false;

  export let count = 0;

  export let adminOnly = false;
  export let theme = 'dark';

  let app_url = $page.props.common.app_url;
  const isExternal = (uri) => {
    return !uri.startsWith('/') && !uri.startsWith(app_url);
  }
</script>

{#if adminOnly && $page.props.auth.user?.role === 'Admin' || !adminOnly || $page.props?.auth?.masquerading}
  <a
    {href}
    class={classNames($$props.class, active && 'active', theme)}
    rel={isExternal(href) ? 'noopener noreferrer' : undefined}
    target={isExternal(href) ? '_blank' : undefined}
  >
    {#if icon}
      <svg xmlns="http://www.w3.org/2000/svg" role="img" class="icon" focusable="false" viewBox={extractViewBox(icon)}>
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html extractData(icon)}
      </svg>
    {/if}
    {title}
    {#if count}
      <span aria-hidden="true">{count}</span>
    {/if}
  </a>
{/if}

<style lang="postcss">
  a {
    @apply flex gap-x-3 rounded-3xl py-1.5 px-4 text-sm leading-6 hover:text-white hover:bg-blue no-underline items-center transition-colors duration-300;

    &.active,
    &:hover {
      @apply text-black lg:text-white bg-blue-100 lg:bg-white/15;

      span {
        @apply bg-white text-blue-950 transition-colors duration-300;
      }

      .icon {
        @apply transition-colors duration-300 lg:fill-white;
      }
    }

    &.light {
      &:hover {
        @apply lg:text-black lg:bg-white;

        .icon {
          @apply lg:fill-black;
        }
      }

      .icon {
        @apply fill-black;
      }
    }


    &.active {
      @apply bg-blue lg:bg-blue-100 text-white lg:text-black;

      .icon {
        @apply fill-white lg:fill-black;
      }
    }

    .icon {
      @apply fill-black lg:fill-white w-4 h-4;
    }

    span {
      @apply ml-auto w-8 min-w-max whitespace-nowrap rounded-full bg-red px-1.5 py-0.5 text-center text-xs leading-5 text-white font-semibold;
    }
  }
</style>
