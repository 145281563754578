<script>
  import { page, useForm, router, inertia } from "@inertiajs/svelte";
  import { onMount } from "svelte";
  import Layout from "@/Layouts/User.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";
  import posthog from "posthog-js";

  let storage = $page.props.profile?.source === '5x5' ? '_bq' : '';

  let form = useForm({
    buyer_id: $page.props?.profile?.id,
    body: $page.props?.pitch?.body,
    pitch_id: $page.props?.pitch?.id,
    type : $page.props?.profile?.type
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if ($page.props?.auth?.loggedIn) {
      $form.post("/pitch");
    } else {
      if (!$form.body || $form.body === "") {
        $form.errors.body = "Pitch is required";
        return;
      }

      if ($form.body?.length > 1000) {
        $form.errors.body = "Pitch length is limited to 1000 characters";
        return;
      }

      let posthogAction = "Pitch created";
      let pitchFormData;
      let pitchData = localStorage.getItem(`pitch_data${storage}_${$page.props.profile?.id }`);
      if (pitchData) {
        pitchFormData = JSON.parse(pitchData);
        posthogAction = "Pitch updated";
      } else {
        pitchFormData = {};
      }

      pitchFormData.body = $form?.body;
      localStorage.setItem(`pitch_data${storage}_${$page.props.profile?.id }`, JSON.stringify(pitchFormData));

      if (posthog && posthog.__loaded) {
        let payload = {
          body: $form.body,
          buyer_id: $page.props.profile?.id,
          buyer_email: $page.props.profile?.email,
          buyer_name: $page.props.profile?.name,
          buyer_slug: $page.props.profile?.slug,
          status: 'Draft'
        };

        if ($page.props?.auth?.loggedIn) {
          if (!posthog._isIdentified()) {
            posthog.identify($page.props.auth.user.id, {
              email: $page.prop?.auth?.user?.email,
              name: $page.prop?.auth?.user?.name,
            });
          }

          payload.seller_id = $page.props.auth.user.id;
          payload.seller_email = $page.props.auth.user.email;
          payload.seller_name = $page.props.auth.user.name;
          payload.seller_slug = $page.props.auth.user.slug;
        }

        posthog.capture(posthogAction, payload);
      }

      router.visit("/@" + $page.props?.profile?.slug + "/set-rate", {
        preserveState: true,
        preserveScroll: true
      });
    }
  };

  onMount(async () => {
    let pitchFormData = localStorage.getItem(`pitch_data${storage}_${$page.props?.profile?.id }`);
    if (pitchFormData) {
      pitchFormData = JSON.parse(pitchFormData);
      $form.body = pitchFormData.body;
    }
  });
</script>

<Layout profile={$page.props?.profile}>
  <Seo
    title={`Pitch ${$page.props?.profile?.name}`}
    breadcrumbs={[
      { name: 'Marketplace', slug: '/marketplace' },
      { name: $page.props?.profile?.name, slug: `/@${$page.props?.profile?.slug}` },
      { name: `Pitch ${$page.props?.profile?.name}` },
    ]}
    metaDescription={$page.props?.profile?.about ? $page.props?.profile?.about : `Pitch ${$page.props?.profile?.name}`}
    ogImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
    twitterImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
  />

  <div class="pitch">
    <div class="head">
      <div>
        <div class="step active">1</div>
        <p>Write your pitch</p>
      </div>

      <span class="ellipsis"></span>

      <div>
        <div class="step">2</div>
        <p>Make an offer</p>
      </div>

      <span class="ellipsis"></span>
      <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
    </div>

    <form method="post" on:submit|preventDefault={(event) => handleSubmit(event)}>
      <Textarea
        name="pitch"
        inputClass="h-72 !text-sm !rounded-lg"
        maxLength="1000"
        placeholder="Enter your pitch here"
        bind:value={$form.body}
        error={$form.errors.body}
        errorClass="!text-white"
        rows="8"
      />
      <div class="flex justify-end mt-4">
        <div class="next">
          <Button
            type="submit"
            size="sm"
            color="red"
            disabled={$form.processing}
            on:submit={(event) => handleSubmit(event)}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>

    <div class="step-indicator">
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
         class="dot active" use:inertia={{preserveScroll: true}}
      >
      </a>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;

        &.active {
          @apply bg-red-500;
        }
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }

    .step-indicator {
      @apply flex justify-center items-center my-4;

      .dot {
        @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

        &.active {
          @apply bg-red-500;
        }
      }
    }
  }
</style>
