<script>
  import Layout from "@/Layouts/Response.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import {page, inertia} from "@inertiajs/svelte";
</script>

<Seo title={`$${$page.props?.response?.fee} Pitch to ${$page.props?.response?.user?.name}`} />

<Layout>
  <div class="pitch">
    <div class="title">Review Your Pitch to {$page.props?.response?.user?.name}</div>

    {#if $page.props?.response?.pitch?.id}
      <div class="body plain">{$page.props?.response?.pitch_body}</div>
    {/if}

    {#if $page.props?.response?.campaign_link?.id}
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="body">{@html $page.props?.response?.pitch_body}</div>
    {/if}
  </div>

  <div class="response">
    <div class="title">{$page.props?.response?.user?.name_plural} response</div>

    <div class="reason">
      {#if $page.props?.response?.status === 'Rejected'}
        <span>{$page.props?.response?.reason}</span>
      {:else}
        <span>I am interested in meeting with you and {$page.props?.response?.seller?.org_name} because...</span>
      {/if}
    </div>
    <div class="body">{$page.props?.response?.body}</div>

    {#if $page.props?.meeting?.meeting_datetime}
      <div class="title">Meeting Details</div>
      <div class="meeting">
        <div class="heading">Date & Time</div>
        <div class="date">{$page.props?.meeting?.meeting_datetime}</div>
        <div class="heading">Time Zone</div>
        <div class="timezone">{$page.props?.meeting?.meeting_timezone}</div>

        <div class="links">
          {#if $page.props?.response?.pitch?.meeting_link}
            <a href={$page.props?.response?.pitch?.meeting_link} target="_blank" rel="noopener noreferrer">Meeting Link</a>
          {/if}

          {#if $page.props?.meeting?.event_link}
            <a href={$page.props?.meeting?.event_link} target="_blank" rel="noopener noreferrer">View in Calendar</a>
          {:else}
            <button use:inertia={{ href: `/app/responses/r/${$page.props?.response?.id}/add-to-calendar`, method: 'post'}} type="button" class="btn">Add to Calendar</button>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply flex flex-col relative mb-4;

    .title {
      @apply font-semibold text-lg mb-2;
    }

    .body {
      @apply text-sm border p-4 rounded-xl;

      &.plain {
        @apply whitespace-pre-line;
      }
    }
  }

  .response {
    @apply mt-8;

    .title {
      @apply text-base font-semibold mb-2;
    }

    .reason {
      @apply text-sm font-semibold mb-2;
    }

    .body {
      @apply whitespace-pre-line text-sm mb-6;
    }

    .meeting {
      @apply p-4 bg-grey-100 rounded-xl mt-2;

      .heading {
        @apply text-sm font-semibold mb-2;
      }

      .date,
      .timezone {
        @apply text-lg leading-none;
      }

      .date {
        @apply mb-4;
      }
    }
  }

  .links {
    @apply mt-4 flex gap-6;

    a, button {
      @apply font-semibold underline;

      &:hover {
        @apply no-underline;
      }
    }
  }
</style>
