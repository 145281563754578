<script>
  import Layout from "@/Layouts/App.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, inertia} from "@inertiajs/svelte";

  export let title;
  export let conference;
  export let conferenceImport = null;
</script>

<Layout>
  <Seo {title} />

  <section>
    <div class="flex flex-col sm:flex-row justify-between items-center py-2.5 px-4 lg:px-8 bg-blue-100 gap-2 sm:gap-4">
      <h1 class="text-xl leading-none sm:leading-normal">Events</h1>
    </div>

    <div class="px-4 sm:px-6 lg:px-8 mt-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h2 class="text-lg leading-none sm:leading-normal">{conference?.name}</h2>
          <p class="mt-2 text-sm text-gray-700">{conference?.description}</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button href={`/app/events/${conference?.id}`} color="red" size="xs" dynamic>Cancel</Button>
        </div>
      </div>
    </div>

    <div class="mt-8 flow-root">
      <nav aria-label="Progress">
        <ol role="list" class="px-4 sm:px-6 lg:px-8 space-y-4 md:flex md:space-x-8 md:space-y-0">
          <li class="md:flex-1 list-none">
            <a use:inertia href={`/app/events/${conference?.id}/upload`} class="group no-underline flex flex-col border-l-4 border-blue-400 py-2 pl-4 hover:border-blue-600 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
              <span class="text-sm font-medium text-blue-600 group-hover:text-blue-600">Step 1</span>
              <span class="text-sm font-medium">Upload File</span>
            </a>
          </li>
          <li class="md:flex-1 list-none">
            {#if conferenceImport}
              <a use:inertia href={`/app/events/${$page.props?.import?.id}/upload/map`} class="group no-underline flex flex-col border-l-4 border-blue-400 py-2 pl-4 hover:border-blue-600 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span class="text-sm font-medium text-blue-600 group-hover:text-blue-600">Step 2</span>
                <span class="text-sm font-medium">Map Headings</span>
              </a>
            {:else}
              <div class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step 2</span>
                <span class="text-sm font-medium">Map Headings</span>
              </div>
            {/if}
          </li>
          <li class="md:flex-1 list-none">
            {#if conferenceImport && conferenceImport?.mappings?.length > 0}
              <a use:inertia href={`/app/events/${$page.props?.import?.id}/upload/import`} class="group no-underline flex flex-col border-l-4 border-blue-400 py-2 pl-4 hover:border-blue-600 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span class="text-sm font-medium text-blue-600 group-hover:text-blue-600">Step 3</span>
                <span class="text-sm font-medium">Import</span>
              </a>
            {:else}
              <div class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step 3</span>
                <span class="text-sm font-medium">Import</span>
              </div>
            {/if}
          </li>
        </ol>
      </nav>

      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </div>
  </section>
</Layout>
