import axios from 'axios';

const api = axios;
api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const api = Axios.create({
//     headers: {
//         "X-Requested-With": "XMLHttpRequest"
//     },
//     withCredentials: true,
// });

export default api;
