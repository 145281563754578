<script>
  import { inertia, page } from "@inertiajs/svelte";
  import { IconLinkedIn } from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import classNames from "classnames";

  export let profile = {
    avatar: null,
    name: null,
    job_title: null,
    job_level: null,
    org_logo: null,
    org_name: null,
    org_slug: null,
    department: null,
    focuses: null,
    vendors: null,
    linkedin_url: null,
    home_base: null,
    timezone: null,
    preferred_employment: null,
    about: null,
  };

  const focusedName = (focuses) => {
    if (focuses == null) {
      return null;
    }

    return focuses.map((focus) => focus.name).join(", ");
  };
</script>

<div {...$$restProps} class={classNames("profile-wrap", $$props.class)}>
  <div class="profile">
    {#if profile?.avatar}
      <a class="p-img" href={`/@${profile?.slug}`}>
        <img decoding="async" src={profile?.avatar} alt={profile?.name} />
      </a>
    {/if}

    <div class="p-info">
      {#if profile?.name}
        <h1>{profile?.name}</h1>
      {/if}

      {#if profile?.job_level || profile?.job_title}
        <p>{profile?.job_level ?? profile?.job_title}{profile?.org_name ? ` at ${profile?.org_name}` : ''}</p>
      {:else}
        <p>{profile?.org_name ?? ''}</p>
      {/if}
    </div>
  </div>

  {#if profile?.response_status}
    <div class="status">
      <div class="field">
        <h3>Response Status</h3>
        <p>{profile?.response_status}</p>
      </div>
    </div>
  {/if}

  <div class="detail">
    <div class="company">
      <h2>Company</h2>

      <div class="wrap-info">
        {#if profile?.org_logo || profile?.org_name || profile?.buyers}
          <a href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${profile?.org_slug}` : `/org/${profile?.org_slug}`} use:inertia>
            <img
              decoding="async"
              src={profile?.org_logo}
              alt={profile?.org_name}
            />
          </a>

          {#if profile?.org_name}
            <p><a href={$page.props?.auth.loggedIn ? `/app/marketplace/org/${profile?.org_slug}` : `/org/${profile?.org_slug}`} use:inertia>{profile?.org_name}</a></p>
          {/if}
        {/if}
      </div>

      <div class="field">
        {#if profile?.job_title || profile?.job_level}
          <h3>My Position in the Company</h3>
          <p>{profile?.job_title ? profile?.job_title : profile?.job_level}</p>
        {/if}

        {#if profile?.department}
          <div class="field">
            <h3>My Department</h3>
            <p>{profile?.department}</p>
          </div>
        {/if}

        {#if profile?.focuses?.length > 0}
          <div class="field">
            <h3>Focused on</h3>
            <p>{focusedName(profile?.focuses)}</p>
          </div>
        {/if}

        {#if profile?.vendors}
          <div class="field">
            <h3>Vendors we use</h3>
            <p>{profile?.vendors}</p>
          </div>
        {/if}
      </div>
    </div>

    {#if profile?.linkedin_url || profile?.home_base || profile?.timezone || profile?.preferred_employment || profile?.about}
      <div class="personal">
        <h2>Personal</h2>

        {#if profile?.linkedin_url}
          <div class="wrap-info">
            <a href={profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
              <Icon size="xxl" data={IconLinkedIn} />
            </a>
            <p><a href={profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
              {profile?.linkedin_url.replace("http://www.linkedin.com/in/", "")}
            </a>
            </p>
          </div>
        {/if}

        <div class="wrap-field-two">
          {#if profile?.country}
            <div class="personal-details field">
              <h3>My Home base</h3>
              <p>{profile?.state ? profile?.state + ', ' + profile?.country : profile?.country}</p>
            </div>
          {/if}

          {#if profile?.timezone}
            <div class="personal-details field">
              <h3>Timezone</h3>
              <p>{profile?.timezone}</p>
            </div>
          {/if}
        </div>

        {#if profile?.preferred_employment}
          <div class="personal-details field">
            <h3>How I like to work</h3>
            <p>{profile?.preferred_employment}</p>
          </div>
        {/if}

        <div class="personal-details field">
          {#if profile?.about}
            <h3>About me</h3>
            <p>{profile?.about}</p>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="postcss">
  .profile-wrap {
    @apply px-5 md:px-7;

    .profile {
      @apply flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 mb-7;

      .p-info {
        h1 {
          @apply text-3xl text-grey-800 mb-1;

          a {
            @apply hover:underline no-underline;
          }
        }

        p {
          @apply text-sm text-grey mb-1;
        }
      }

      .p-img img {
        @apply w-20 h-20 border-grey-200 rounded-lg object-cover object-center;
      }
    }

    .status {
      @apply mb-6;

      .field {
        @apply w-full;

        h3 {
          @apply text-xs text-black mb-1 uppercase;
        }

        p {
          @apply text-sm text-grey-800 font-normal mb-4;
        }
      }
    }

    .detail {
      @apply flex w-full flex-col md:flex-row gap-8;

      .company {
        @apply w-full md:w-5/12;
      }

      .personal {
        @apply w-full md:w-7/12;
      }

      .wrap-field-two {
        @apply sm:flex w-full;

        .field {
          @apply w-1/2;
        }
      }

      h2 {
        @apply font-semibold text-base leading-5 text-grey-800 mb-7;
      }

      .wrap-info {
        @apply w-full flex items-center mb-5 gap-2;

        img {
          @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
        }

        p {
          @apply mb-0 text-sm;
        }
      }

      .field {
        h3 {
          @apply text-xs text-black mb-1 uppercase;
        }

        p {
          @apply text-sm text-grey-800 font-normal mb-5;
        }
      }
    }
  }
</style>
