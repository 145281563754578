<script>
  import Layout from "@/Layouts/Upload.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, useForm} from "@inertiajs/svelte";

  let form = useForm({
    file: null
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    $form.post(`/app/events/${$page.props?.conference?.id}/upload/process`);
  }
</script>

<Layout title={$page.props?.conference?.name + ' - Upload'} conference={$page.props?.conference}>
  <form action={`/app/events/${$page.props?.conference?.id}/upload/process`} method="post" enctype="multipart/form-data" on:submit={(e) => handleSubmit(e)}>
    <div class="mt-8">
      <label for="file" class="block text-sm font-medium text-gray-700">Upload File</label>
      <div class="mt-1 flex items-center">
        <input
          type="file"
          id="file"
          name="file"
          on:input={(e) => $form.file = e.target.files[0]}
          class="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
      {#if $form.errors.file}
        <p class="m-0 mt-2 text-red-600 leading-snug text-xs" id="file-error">{$form.errors.file}</p>
      {/if}
    </div>

    <div class="mt-8">
      <Button type="submit" on:click={(e) => handleSubmit(e)} color="blue" size="sm" dynamic>Upload</Button>
    </div>
  </form>

  {#if $page.props?.uploads?.length > 0}
    <h2 class="mt-20 mb-4 text-lg font-semibold text-gray-900">Uploads</h2>
    <div class="pt-4 border-t border-grey-400">
      <table class="w-full text-left">
        <thead class="bg-white">
          <tr>
            <th scope="col" class="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">ID</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Rows</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Status</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">Created</th>
            <th scope="col" class="relative py-3.5 pl-3">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {#each $page.props?.uploads as upload}
            <tr>
              <td class="relative py-4 pr-3 text-sm font-medium text-gray-900">
                {#if upload.status === 'draft'}
                  <a href={`/app/events/${upload?.id}/upload/map`}>{upload?.id}</a>
                {:else}
                  {upload.id}
                {/if}
              </td>
              <td class="px-3 py-4 text-sm text-gray-500 sm:table-cell">{upload?.rows}</td>
              <td class="px-3 py-4 text-sm text-gray-500 md:table-cell">{upload?.status}</td>
              <td class="px-3 py-4 text-sm text-gray-500">{upload?.created_at}</td>
              <td class="relative py-4 pl-3 text-right text-sm font-medium flex gap-4 justify-end">
                {#if upload.status !== 'completed'}
                  <a href={`/app/events/${upload?.id}/upload/map`}>Continue</a>
                {/if}
                <a href={`/app/events/${upload?.id}/upload/download`}>Download</a>
                {#if upload.status !== 'processing' && upload.status !== 'imported'}
                  <a href={`/app/events/${upload?.id}/upload/delete`}>Delete</a>
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  {/if}
</Layout>
