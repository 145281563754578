<script>
  import Layout from "@/Layouts/App.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Range from "@/Components/Forms/Range.svelte";
  import {page, useForm, router} from "@inertiajs/svelte";

  let form = useForm({
    pitch_price: $page.props.auth?.user?.pitch_price ?? 25
  });

  $: changed = $form.pitch_price !== $page.props.auth?.user?.pitch_price;

  const handleCancel = () => {
    $form.pitch_price = $page.props.auth?.user?.pitch_price;
  }

  const handleSave = () => {
    $form.post("/app/manage-rates", {
      onSuccess: () => {
        router.reload({ only: ['auth'] });
      }
    });
  }
</script>

<Seo title="Manage your Rates" />

<Layout>
  <div class="content">
    <h1>Manage your Rates</h1>
    <Breadcrumb crumbs={[
        { title: 'Menu', url: '/' },
        { title: 'Manage your Rates' }
    ]} />

    <form method="post" on:submit|preventDefault={handleSave}>
      <div class="field">
        <div>
          <label for="pitch_price">What is minimum offer you would answer a pitch for?</label>
          <p>Your response requires a minimum of 250 characters (~50 words).</p>
        </div>

        <Range
          class="w-full lg:w-1/2"
          name="pitch_price"
          minLabel="$25"
          min="25"
          maxLabel="$500"
          max="500"
          valuePrefix="$"
          bind:value={$form.pitch_price}
        />
        {#if $form.errors.pitch_price}
          <div class="!mt-1 text-red-600 text-sm">{$form.errors.pitch_price}</div>
        {/if}
      </div>

      <div class="actions">
        <Button type="button" color="tertiary" size="sm" on:click={() =>handleCancel()} disabled={!changed}>Cancel</Button>
        <Button type="submit" color="secondary" size="sm" on:click={(event) =>handleSave(event)} disabled={!changed}>Save</Button>
      </div>
    </form>
  </div>
</Layout>

<style lang="postcss">
  .content {
    @apply py-8 lg:py-6 px-6 lg:px-8;
  }

  h1 {
    @apply text-3xl md:text-4xl mb-3;
  }

  form {
    @apply mt-12 flex flex-col gap-6;
  }

  .field {
    @apply p-6 bg-grey-100 rounded-2xl flex flex-col md:flex-row justify-between items-center md:gap-8 border border-grey-200;

    & > div {
      @apply w-full lg:w-1/2;
    }

    label {
      @apply font-semibold text-base leading-tight block;
    }

    p {
      @apply text-xs leading-tight mt-3.5 text-grey-600;
    }
  }

  .actions {
    @apply flex justify-end gap-4;
  }
</style>
