<script>
  import {
    IconAmex,
    IconBin,
    IconDiners,
    IconDiscover,
    IconJcb,
    IconMastercard,
    IconVisa
  } from "@/Components/Assets/Icons";
  import Icon from "@/Components/Assets/Icon.svelte";
  import classNames from "classnames";
  import {useForm} from "@inertiajs/svelte";

  /**
   * @typedef {'mini' | 'default'} theme
   * @typedef {'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb'} brand
   * @typedef {'Visa' | 'Mastercard' | 'American Express' | 'Discover' | 'Diners' | 'JCB'} brand_format
   */

  /** @satisfies theme */
  export let theme = 'default'

  /** @type {string} id */
  export let id;

  /** @satisfies brand */
  export let brand;

  /** @satisfies brand_format */
  export let brand_formatted;

  /** @type {number} last4 */
  export let last4;

  /** @type {string} expiry */
  export let expiry;

  /** @type {boolean} expired */
  export let expired;

  /** @type {string} name */
  export let name;

  /** @type {string} address */
  export let address = '';

  export let default_method = false;

  let defaultForm = useForm({ id: id });
  let deleteForm = useForm({ id: id });

  const setDefaultCard = (id) => {
    defaultForm.id = id;
    $defaultForm.post('/app/settings/payment-methods/default');
  }

  const deleteCard = (id) => {
    $deleteForm.id = id;
    $deleteForm.post('/app/settings/payment-methods/delete');
  }
</script>

<div class={classNames('wrap', theme, $$props.class)}>
  <div class="logo">
    {#if brand === 'visa'}
      <Icon data={IconVisa} class="!w-full !h-auto" />
    {:else if brand === 'mastercard'}
      <Icon data={IconMastercard} class="!w-full !h-auto" />
    {:else if brand === 'amex'}
      <Icon data={IconAmex} class="!w-full !h-auto" />
    {:else if brand === 'discover'}
      <Icon data={IconDiscover} class="!w-full !h-auto" />
    {:else if brand === 'diners'}
      <Icon data={IconDiners} class="!w-full !h-auto" />
    {:else if brand === 'jcb'}
      <Icon data={IconJcb} class="!w-full !h-auto" />
    {/if}
  </div>

  <div class="card">
    {#if theme !== 'mini'}<h4>{brand_formatted}</h4>{/if}

    <div class="details">
      <div>
        <p><span>Card Number</span></p>
        <p>XXXX XXXX XXXX {last4}</p>
      </div>
      <div>
        <p><span>Card Holder Name</span></p>
        <p>{name}</p>
      </div>
      <div class={expired && 'expiry'}>
        {#if !expired}
          <p><span>Expiry</span></p>
        {:else}
          <p class={expired && 'expired'}><span>EXPIRED</span></p>
        {/if}
        <p>{expiry}</p>
      </div>
    </div>

     {#if address}
        <div class="address">
          <div>
            <p><span>Address</span></p>
            <p>{address}</p>
          </div>
        </div>
     {/if}

    {#if theme !== 'mini' && !expired}
      <div>
        <form method="post" on:submit|preventDefault={setDefaultCard}>
          <label for={`id-${id}`}>
            <input
              id={`id-${id}`}
              name={`id-${id}`}
              type="checkbox"
              checked={default_method ? 'checked': undefined}
              on:change={() => setDefaultCard(id)}
            />
            <span>Make Default Card</span>
          </label>
        </form>
      </div>
    {/if}
  </div>

  {#if theme !== 'mini'}
    <div class="actions flex">
      <button on:click|preventDefault={() => deleteCard(id)}>
        <Icon data={IconBin} size="sm" class="fill-red" />
      </button>
    </div>
  {/if}
</div>

<style lang="postcss">
  .wrap {
    @apply bg-grey-100 rounded-xl flex items-start gap-4 p-4 sm:min-w-[28rem] md:min-w-[30rem];

    &.active {
      @apply bg-blue-100/95;
    }
  }

  .logo {
    @apply w-16 sm:w-20 md:w-24 shrink-0;
  }

  .card {
    @apply w-full;

    h4 {
      @apply text-lg mb-3 leading-none;
    }

    .address {
      @apply my-3;

      div {
        @apply text-xs;
      }

      p {
        @apply m-0 leading-snug;

        span {
          @apply text-grey-600;
        }
      }
    }

    .details {
      @apply flex flex-wrap gap-x-6 gap-y-2 mb-3 justify-between;

      .expiry{
        p{
          @apply text-red-700;
          span{
            @apply text-red-700;
          }

        }
      }

      div {
        @apply text-xs;
      }

      p {
        @apply m-0 leading-snug;

        span {
          @apply text-grey-600;
        }
      }
    }

    form {
      @apply text-xs;

      label {
        @apply flex gap-2 items-center;
      }
    }
  }

  .actions {
    @apply ml-auto;
  }
</style>
