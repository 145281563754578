<script>
  import Layout from "@/Layouts/App.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import {page, router, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Alert from "@/Components/Elements/Alert.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconBin} from "@/Components/Assets/Icons/index.js";
  import Loader from "@/Components/Elements/Loader.svelte";
  import api from "@/Lib/api.js";
  import {init} from "@airwallex/payouts-web-sdk";
  import {failure} from "@/Lib/notices";
  import {onMount} from "svelte";

  let beneficiaryComponent;
  let beneficiaryComponentReady = false;
  let beneficiaryComponentError = false;
  let accountSuccess = false;
  let confirm = false;
  let processing = false;

  let environment = 'prod';
  if ($page.props.common.app_env === 'local' || $page.props.common.app_env === 'development' || $page.props.common.app_env === 'staging') {
    environment = 'demo';
  }

  const initialiseProvider = async () => {
    const result = await init({
      langKey: 'en',
      env: environment,
      authCode: $page.props.auth_code,
      clientId: $page.props.client_id,
      codeVerifier: $page.props.code_verifier,
    })

    let params = {
      defaultValues: {
        beneficiary: {
          entity_type: 'PERSONAL',
          bank_details: {
            local_clearing_system: 'BANK_TRANSFER'
          },
          additionalInfo: {
            personalEmail: $page.props.auth?.user?.email
          }
        },
        payment_methods: ['LOCAL']
      }
    };

    beneficiaryComponent = result.createElement('beneficiaryForm', params);
    beneficiaryComponent.mount('#beneficiary-form');

    beneficiaryComponent.on('ready', () => {
      beneficiaryComponentReady = true;
    });

    beneficiaryComponent.on('error', () => {
      beneficiaryComponentError = true;
    });
  }

  let accountForm = useForm({
    payload: null
  });

  const createAccount = async (event) => {
    event.preventDefault();

    if (!beneficiaryComponentReady || beneficiaryComponentError) {
      return;
    }

    const formResult = await beneficiaryComponent.submit();
    if (formResult.errors) {
      return;
    }

    $accountForm.payload = formResult.values;
    $accountForm.post('/app/wallet/add-account', {
      onSuccess: () => {
        beneficiaryComponent.unmount()
        router.reload()
        accountSuccess = true;
      }
    });
  }

  const deleteAccount = async () => {
    await api.post('/app/wallet/delete-account')
      .then((res) => {
        if (res.data.error) {
          failure(res.data.error);
        } else {
          window.location = '/app/wallet';
        }
      })
  }

  const confirmPayment = (event) => {
    event.preventDefault();
    confirm = true;
  }

  const cancelPayment = (event) => {
    event.preventDefault();
    confirm = false;
  }

  let payoutForm = useForm();

  const processPayout = async (event) => {
    processing = true;
    event.preventDefault();
    await $payoutForm.post('/app/wallet/payout');
  }

  onMount(() => {
    if (!$page.props.account) {
      initialiseProvider();
    }
  });
</script>

<Seo title="Wallet" />

<Layout>
  <div class="content">
    <div class="header">
      <div>
        <h1>Wallet</h1>
        <Breadcrumb crumbs={[
            { title: 'Menu', url: '/' },
            { title: 'Wallet', url: '/app/wallet' }
        ]} />
      </div>
    </div>

    <div class="main">
      <p>You've earned a total of <strong>${$page.props?.wallet?.lifetime_total}</strong> with Pitchfire!</p>

      {#if $page.props?.wallet?.display_total === 0}
        <p>You currently have no funds available to withdraw</p>
      {:else}
        {#if $page.props?.wallet?.leads_pending_total > 0}
          <p>You have <strong>${$page.props?.wallet?.leads_pending_total}</strong> pending authorisation, <strong>${$page.props?.wallet?.allowed_total}</strong> payable now.</p>
        {:else}
          <p>You have <strong>${$page.props?.wallet?.allowed_total}</strong> available to withdraw now.</p>
        {/if}
      {/if}

      <p>You've withdrawn <strong>${$page.props?.wallet?.withdrawn}</strong> so far.</p>

      {#if !$page.props.transfer_permitted}
        <p>The minimum withdrawal amount is $25</p>
      {/if}

      {#if $page.props.account}
        <h3>Your bank account details:</h3>

        <div class="bank">
          <div>
            {#if $page.props.account.name && $page.props.account.name !== '-'}<p>{$page.props.account.name}</p>{/if}
            <p>{$page.props.account.bank_name} ({$page.props.account.account_currency})</p>
            <p>{$page.props.account.account_name}</p>
          </div>

          <button on:click={(event) => deleteAccount(event)}>
            <Icon data={IconBin} class="fill-red" />
          </button>
        </div>

        <div class="actions">
          {#if !$page.props.pending_pitches}
            {#if $page.props.transfer_permitted}
              {#if confirm}
                <div class="flex gap-4">
                  <Button color="tertiary" on:click={(event) => cancelPayment(event)}>Cancel</Button>
                  <Button color="secondary" on:click={(event) => processPayout(event)} loading={processing}>Confirm Transfer</Button>
                </div>
              {:else}
                <Button on:click={(event) => confirmPayment(event)}>Transfer to Bank</Button>
              {/if}
              <p>You will receive <strong>${$page.props?.wallet?.allowed_total}</strong>. Standard transfer fees apply</p>
            {/if}
          {:else}
            <a href="/app/respond" class="btn primary">Respond to Pitches</a>
            <p>Note: before transferring, respond to your pending sales pitches. Standard transfer fees apply.</p>
          {/if}
        </div>
      {:else}
        {#if accountSuccess}
          <Alert type="success" title="Account details successfully saved" />
        {:else}
          <h2>Add Account</h2>
          <div class="form">
            {#if beneficiaryComponentError}
              <Alert type="error" id={`alt-${$page.props.auth_code}`} message="An error has occurred" />
            {/if}

            <div id="beneficiary-form"></div>

            <form class="actions" method="post" on:submit|preventDefault={createAccount}>
              <Button on:click={(event) => createAccount(event)} disabled={!beneficiaryComponentReady || beneficiaryComponentError}>Save Details</Button>
              {#if !beneficiaryComponentReady}<Loader />{/if}
            </form>
          </div>
        {/if}
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  .content {
    @apply py-8 lg:py-6 px-6 lg:px-8;

    & > div {
      @apply w-full py-8 lg:py-10 max-w-6xl;
    }
  }

  .header {
    @apply flex justify-between items-end;

    h1 {
      @apply text-3xl md:text-4xl mb-3;
    }
  }

  .main {
    strong {
      @apply text-violet;
    }

    h2,
    h3 {
      @apply text-lg md:text-2xl;
    }

    h3 {
      @apply mt-8;
    }
  }

  .bank {
    @apply px-4 py-2 border rounded-xl border-blue-50 bg-grey-100 flex justify-between mt-4 mb-8 gap-4 items-center;

    p {
      @apply m-0;
    }
  }

  #beneficiary-form {
    @apply border px-2 py-4 rounded-lg border-blue-50 my-4;
  }

  .actions {
    @apply md:flex gap-4 justify-start items-center;

    .btn {
      @apply whitespace-nowrap text-white justify-center font-semibold rounded-lg border focus:ring-2 focus:outline-none cursor-pointer inline-flex gap-2 items-center px-4 leading-none py-3 text-lg no-underline;

      &.primary {
        @apply bg-blue hover:bg-blue-600 border-blue focus:ring-blue-300 disabled:border-blue-100 disabled:bg-blue-100 disabled:text-blue-400;
      }
    }

    p {
      @apply mb-0 mt-4 md:m-0;
    }
  }
</style>
