<script>
  import Layout from "@/Layouts/App.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import { page, useForm, inertia } from "@inertiajs/svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import { IconSearch, IconFilter } from "@/Components/Assets/Icons/index.js";
  import InfiniteScroll from "@/Components/Layout/InfiniteScroll.svelte";
  import { onMount } from "svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  // import Select from "@/Components/Forms/Select.svelte";
  // import SelectBox from "@/Components/Forms/SelectBox.svelte";
  // import classNames from "classnames";
  // import Alert from "@/Components/Elements/Alert.svelte";
  import FilterRow from "@/Components/Elements/FilterRow.svelte";
  import MarketplaceResult from "@/Components/Elements/MarketplaceResult.svelte";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import { persisted } from "svelte-persisted-store";
  import cn from "classnames";
  import Alert from "@/Components/Elements/Alert.svelte";

  let results = $page.props?.results?.data;

  let currentPage = 1;
  let currentTotal = $page.props?.results?.meta?.total ?? 0;
  let isOpen = false;
  let loading = false;
  let filterCount = 0;
  let isAudienceFormOpen = false;
  // let audiences = [];

  const managementStore = persisted("marketplace_management", []);
  const departmentsStore = persisted("marketplace_departments", []);
  const revenueStore = persisted("marketplace_revenues", []);
  const employeesStore = persisted("marketplace_employees", []);
  const industriesStore = persisted("marketplace_industries", []);
  const countriesStore = persisted("marketplace_countries", []);

  let form = useForm({
    search: "",
    audience: "",
    management: [],
    departments: [],
    revenue: [],
    employees: [],
    industries: [],
    countries: [],
    page: currentPage,
  });

  const formatNumber = (number) => {
    return number > 0 ? new Intl.NumberFormat().format(number) : 0;
  };

  const updateFilter = (event, key) => {
    const {checked, value} = event.target;

    if (checked) {
      $form[key].push(value);
    } else {
      $form[key] = $form[key].filter((v) => v !== value);
    }

    if (key === 'management') {
      managementStore.set($form[key]);
    } else if (key === 'departments') {
      departmentsStore.set($form[key]);
    } else if (key === 'revenue') {
      revenueStore.set($form[key]);
    } else if (key === 'employees') {
      employeesStore.set($form[key]);
    }

    updateFilterCount();
  };

  const handleSubmit = (event, more = false) => {
    event?.preventDefault();
    loading = !more;
    $form.page = more ? currentPage : 1;

    $form
      .transform((data) => {
        data.industries = data.industries ? data.industries?.map((x) => x.value) : [];
        data.countries = data.countries ? data.countries?.map((x) => x.value) : [];
        return data;
      })
      .post('/app/marketplace', {
        preserveScroll: true,
        only: ['results'],
        onSuccess: (response) => {
          loading = false;
          let data = response?.props?.results?.data;
          results = more ? [ ...results, ...data ] : data;
          currentTotal = response?.props?.results?.meta?.total;
        },
        onFinish: () => {
          loading = false;
        },
        onCancel: () => {
          loading = false;
        }
      });
  };

  const updateFilterCount = () => {
    filterCount =
      ($form?.industries?.length ?? 0) +
      ($form?.countries?.length ?? 0) +
      ($form?.management?.length ?? 0) +
      ($form?.departments?.length ?? 0) +
      ($form?.employees?.length ?? 0) +
      ($form?.revenue?.length ?? 0);
  };

  const updateIndustries = (event) => {
    let industries = event.detail.map((x) => x.value);
    industriesStore.set(industries);
    updateFilterCount();
  };

  const updateCountries = (event) => {
    let countries = event.detail.map((x) => x.value);
    countriesStore.set(countries);
    updateFilterCount();
  };

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    $form.search = urlParams.get("s") || "";

    $form.management = $managementStore;
    $form.departments = $departmentsStore;
    $form.revenue = $revenueStore;
    $form.employees = $employeesStore;
    $form.industries = $industriesStore;
    $form.countries = $countriesStore;

    updateFilterCount();
    handleSubmit();
  });

  const searchInput = (event) => {
    event.preventDefault();

    $form.management = [];
    $form.departments = [];
    $form.revenue = [];
    $form.employees = [];
    $form.industries = [];
    $form.countries = [];

    updateFilterCount();
    handleSubmit(event);
  };

  const handleIsOpen = (event) => {
    isOpen = event;
  };

  const resetForm = (event) => {
    loading = true;
    $form.cancel();

    currentPage = 1;

    // Explicitly reset checkboxes
    $form.search = '';
    $form.management = [];
    $form.departments = [];
    $form.revenue = [];
    $form.employees = [];
    $form.countries = [];
    $form.industries = [];

    managementStore.set([]);
    departmentsStore.set([]);
    revenueStore.set([]);
    employeesStore.set([]);
    countriesStore.set([]);
    industriesStore.set([]);

    updateFilterCount();

    $form.reset();
    $form.clearErrors();

    handleSubmit(event);
  };

  // const saveAudience = async () => {
  //   loading = true;
  //
  //   // Prepare the data
  //   const data = $form.transform((data) => {
  //     delete data.search;
  //     delete data.page;
  //
  //     data.industries = data.industries
  //       ? data.industries.map((x) => x.value)
  //       : [];
  //     const updatedData = data;
  //     delete updatedData.audience_name;
  //     return updatedData;
  //   });
  //
  //   try {
  //     const response = await axios.post(
  //       "/app/audince",
  //       {filters: data, name: $form.audience_name},
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-CSRF-TOKEN": document
  //             .querySelector('meta[name="csrf-token"]')
  //             .getAttribute("content"),
  //         },
  //       },
  //     );
  //     // audiences = response.data.results;
  //     isAudienceFormOpen = false;
  //   } catch (error) {
  //     console.error("Error during the request:", error);
  //   } finally {
  //     loading = false;
  //   }
  // };

  // const handleAudience = (event) => {
  //   const data = JSON.parse(event.target.value);
  //   $form.management = data?.management;
  //   $form.departments = data?.departments;
  //   $form.revenue = data?.revenue;
  //   $form.industries = data?.industries;
  // }

  $: isDisabled = filterCount === 0;
</script>

<Layout>
  <Seo title="Marketplace" />

  <section>
    <div class="flex flex-col sm:flex-row justify-between items-center py-2.5 px-4 lg:px-8 bg-blue-100 gap-2 sm:gap-4">
      <h1 class="text-xl leading-none sm:leading-normal">Marketplace</h1>

      <div class="flex justify-center sm:justify-between items-center gap-2 sm:gap-6 w-full sm:w-auto">
        <div class="flex justify-between items-center gap-2">
          <h6 class="text-sm font-normal hidden sm:block">Filter View</h6>
          <button on:click={() => handleIsOpen(true)}>
            <Icon data={IconFilter} size="md" />
          </button>

          <div class={cn(isOpen ? 'translate-x-0 shadow-pf-ac' : 'translate-x-full shadow-none', 'bg-blue-50 fixed inset-y-0 right-0 z-50 w-full max-w-[460px] transform transition duration-300 ease-in-out')}>
            <div class="flex items-center justify-between gap-2 py-2.5 px-4 lg:px-8 border-b border-grey-200 shadow-sm">
              {#if !isAudienceFormOpen}
                <h2 class="text-sm sm:text-xl leading-none sm:leading-normal">Filters ({filterCount})</h2>

                <div class="flex items-center gap-4 justify-between">
                  <div class="flex items-center gap-2 justify-between">
                    <Button
                      disabled={loading || form.processing}
                      btnClass="text-white hover:text-gray-900 focus:outline-none bg-none py-1 px-2 text-xs	bg-red-500"
                      on:click={(event) => handleSubmit(event)}>Filter
                    </Button>

                    <Button
                      disabled={loading || form.processing}
                      btnClass={`text-gray-600 hover:text-gray-900 focus:outline-none bg-none py-1 px-2 text-xs ${
                        isDisabled || loading || form.processing ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      on:click={(event) => resetForm(event)}>Reset
                    </Button>

<!--                      <Button -->
<!--                        disabled={isDisabled} -->
<!--                        btnClass={`text-gray-600 hover:text-gray-900 focus:outline-none bg-none py-1 px-2 text-xs ${ -->
<!--                          isDisabled -->
<!--                            ? "opacity-50 cursor-not-allowed" -->
<!--                            : "bg-blue-800 text-white" -->
<!--                        }`} -->
<!--                        on:click={() => (isAudienceFormOpen = true)}>Create audience -->
<!--                      </Button> -->
                  </div>

                  <Button
                    btnClass="text-gray-600 hover:text-gray-900 focus:outline-none bg-none border-none	text-black"
                    on:click={() => handleIsOpen(false)}
                  >✕
                  </Button>
                </div>
              {:else}
                <h2 class="text-xl font-semibold flex">
                  <Input
                    class="min-h-10"
                    name="audience_name"
                    bind:value={$form.audience_name}
                    type="text"
                    size="sm"
                    required
                  />
<!--                    <Button -->
<!--                      disabled={isDisabled || loading || form.processing} -->
<!--                      btnClass={`text-white hover:text-gray-900 focus:outline-none border-red-500 bg-red-500 py-1 px-2 text-xs ml-2 ${ -->
<!--                        isDisabled || loading || form.processing ? "opacity-50 cursor-not-allowed" : "" -->
<!--                      }`} -->
<!--                      on:click={(event) => saveAudience(event)}>Save -->
<!--                    </Button -->
<!--                    > -->

                  <Button
                    disabled={isDisabled || loading || form.processing}
                    btnClass={`text-gray-600 hover:text-gray-900 focus:outline-none bg-none py-1 px-2 text-xs ml-2 ${
                      isDisabled || loading || form.processing
                        ? "opacity-50 cursor-not-allowed"
                        : "bg-blue-800 text-white"
                    }`}
                    on:click={() => (isAudienceFormOpen = false)}>Cancel
                  </Button
                  >
                </h2>

                <Button
                  btnClass="text-gray-600 hover:text-gray-900 focus:outline-none bg-none border-none	text-black"
                  on:click={() => (isAudienceFormOpen = false)}
                >✕
                </Button>
              {/if}
            </div>

            <div class="overflow-scroll overscroll-contain h-[calc(100%-60px)]">
              <div class="p-4 lg:px-8 lg:py-6 border-b border-grey-200">
<!--                    <FilterRow heading="BUYER AUDIENCE"> -->
<!--                      <Select -->
<!--                        name="audience" -->
<!--                        placeholder="Select audience" -->
<!--                        size="sm w-full" -->
<!--                        options={audiences} -->
<!--                        on:change={(event) => handleAudience(event)} -->
<!--                      /> -->
<!--                    </FilterRow> -->

                {#if $page.props?.tags?.management_level}
                  <FilterRow heading="MANAGEMENT LEVEL" checkboxes class="mb-6">
                    {#each $page.props?.tags?.management_level as level, key}
                      <Input
                        type="checkbox"
                        id={`management[${key}]`}
                        name={`management[${key}]`}
                        value={level.value}
                        checked={$form.management.includes(level.value)}
                        on:change={(event) => updateFilter(event, 'management')}
                        label={level.label}
                        size="xs"
                        inputClass="h-5 !w-5"
                      />
                    {/each}
                  </FilterRow>
                {/if}

                {#if $page.props?.tags?.department}
                  <FilterRow heading="DEPARTMENT" checkboxes class="mb-6">
                    {#each $page.props.tags.department as department, key}
                      <Input
                        type="checkbox"
                        id={`department[${key}]`}
                        name={`department[${key}]`}
                        value={department.value}
                        checked={$form.departments.includes(department.value)}
                        on:change={(event) => updateFilter(event, 'departments')}
                        label={department.label}
                        size="xs"
                        inputClass="h-5 !w-5"
                      />
                    {/each}
                  </FilterRow>
                {/if}

                {#if $page.props?.tags?.revenue_range}
                  <FilterRow heading="COMPANY REVENUE" checkboxes class="mb-6">
                    {#each $page.props?.tags?.revenue_range as revenue, key}
                      <Input
                        type="checkbox"
                        id={`revenue[${key}]`}
                        name={`revenue[${key}]`}
                        value={revenue.value}
                        checked={$form.revenue.includes(revenue.value)}
                        on:change={(event) => updateFilter(event, 'revenue')}
                        label={revenue.label}
                        size="xs"
                        inputClass="h-5 !w-5"
                      />
                    {/each}
                  </FilterRow>
                {/if}

                {#if $page.props?.tags?.employees_range}
                  <FilterRow heading="EMPLOYEES" checkboxes>
                    {#each $page.props?.tags?.employees_range as employees, key}
                      <Input
                        type="checkbox"
                        id={`employees[${key}]`}
                        name={`employees[${key}]`}
                        value={employees.value}
                        checked={$form.employees.includes(employees.value)}
                        on:change={(event) => updateFilter(event, 'employees')}
                        label={employees.label}
                        size="xs"
                        inputClass="h-5 !w-5"
                      />
                    {/each}
                  </FilterRow>
                {/if}
              </div>

              <div class="p-4 lg:px-8 lg:py-6">
                {#if $page.props?.countries}
                  <div class="mb-6">
                    <p class="text-xs mb-1.5">COUNTRY</p>
                    <SelectBox
                      bind:value={$form.countries}
                      id="countries"
                      name="countries"
                      disabled={loading || form.processing}
                      options={$page.props?.countries}
                      placeholder="Select Countries"
                      on:change={(event) => updateCountries(event)}
                    />
                  </div>
                {/if}

                {#if $page.props?.tags?.industry}
                  <p class="text-xs mb-1.5">INDUSTRY</p>
                  <SelectBox
                    bind:value={$form.industries}
                    id="industries"
                    name="industries"
                    disabled={loading || form.processing}
                    options={$page.props?.tags?.industry}
                    placeholder="Select Industries"
                    on:change={(event) => updateIndustries(event)}
                  />
                {/if}
              </div>
            </div>
          </div>
        </div>

        <form method="post" action="/app/marketplace" on:submit={(event) => searchInput(event)} class="w-full sm:w-auto">
          <Input
            id="search"
            type="text"
            name="search"
            wrapperClass="border border-grey-200 rounded-full px-2 pr-4 bg-white"
            inputClass="!border-0 sm:min-w-72"
            class="relative mb-4 flex"
            placeholder="Search for a buyer, company or job title"
            size="sm"
            autofocus
            minlength="3"
            slot="right"
            error={$form.errors.search}
            disabled={loading || form.processing}
            bind:value={$form.search}
          >
            <button
              type="submit"
              disabled={loading || form.processing}
              on:click={(event) => searchInput(event)}
              slot="right"
              class="search-btn"
            >
              <Icon data={IconSearch} size="sm" />
            </button>
          </Input>
        </form>
      </div>
    </div>

    <div class="p-4 lg:px-8">
      <div class="mb-4 flex items-center gap-6">
        <span class="text-base font-semibold">{formatNumber(currentTotal)} results</span>

        <Button btnClass="px-3 py-2 text-sm leading-none border-grey-300 !font-medium" on:click={() => handleIsOpen(true)}>
          <Icon data={IconFilter} size="md" />
          <span>{filterCount} filters</span>
        </Button>

        <p class="hidden sm:block ml-auto mb-0"><a href="https://meetings.hubspot.com/jeremy-leveille" target="_blank" rel="noopener noreferrer">Unlock more prospects in PitchFire</a></p>
      </div>

      <p class="block sm:hidden"><a href="https://meetings.hubspot.com/jeremy-leveille" target="_blank" rel="noopener noreferrer">Unlock more prospects in PitchFire</a></p>

      {#if loading}
        <Loader />
      {:else if results?.length > 0}
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4 overflow-auto max-h-[calc(100vh-205px)] lg:max-h-[calc(100vh-135px)]">
          {#each results as u}
            <MarketplaceResult
              href={`/app/marketplace/@${u.slug}`}
              name={u.name}
              image={u.avatar}
              imageAlt={u.name}
              title={u.job_title ?? u.job_level}
              org_name={u.org_name}
              org_url={`/app/marketplace/org/${u.org_slug}`}
              price={u.price}
              theme="minimal"
              size="xl"
            >
              <div slot="actions" class="text-center mt-4 flex flex-col gap-2">
                {#if $page.props?.auth?.user?.role === "Admin"}
                  <div>Status: {u?.status}</div>
                {/if}

                {#if $page.props?.auth?.user?.role === "Admin" && u.source === 'db'}
                  <a
                    href="/masquerade/{u.id}"
                    use:inertia
                    class="block w-auto text-xs">Login as</a
                  >
                {/if}

                {#if u?.deleted_at}
                  <Alert type="error" message="Profile Deleted" />
                {:else}
                  {#if u.unsubscribed}
                    <Button dynamic size="sm" class="w-full" href={`/app/marketplace/@${u.slug}`}>Unsubscribed</Button>
                  {:else if u.pitched}
                    <Button dynamic size="sm" class="w-full" href={`/app/marketplace/@${u.slug}`}>Pitched {u.pitched}</Button>
                  {:else}
                    <Button dynamic size="sm" color="red" class="w-full" href={`/app/marketplace/@${u.slug}`}>Starting at ${u?.price ?? 25}</Button>
                  {/if}
                {/if}
              </div>
            </MarketplaceResult>
          {/each}

          <InfiniteScroll
            hasMore={true}
            threshold={1000}
            on:loadMore={(event) => {
              currentPage++;
              handleSubmit(event, true);
            }}
          />
        </div>
      {:else}
        <div>
          <p>Sorry, we were unable to find any results for your selection</p>
        </div>
      {/if}
    </div>
  </section>
</Layout>
