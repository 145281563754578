<script>
  import Layout from "@/Layouts/App.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {useForm, page} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";

  let form = useForm({
    name: $page.props?.conference?.name,
    description: $page.props?.conference?.description,
    logo: null,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    $form.post(`/app/events/${$page.props?.conference?.id}/update`);
  }
</script>

<Layout>
  <Seo title="Create Event" />

  <section>
    <div class="flex flex-col sm:flex-row justify-between items-center py-2.5 px-4 lg:px-8 bg-blue-100 gap-2 sm:gap-4">
      <h1 class="text-xl leading-none sm:leading-normal">Edit Event</h1>
    </div>

    <div class="px-4 sm:px-6 lg:px-8 mt-8">
      <form on:submit={(e) => handleSubmit(e)} class="max-w-md flex flex-col gap-4">
        <Input
          loading={$form.processing}
          type="text"
          name="name"
          label="Name"
          size="xs"
          placeholder="Enter the name of the event"
          bind:value={$form.name}
          error={$form.errors.name}
        />

        <Textarea
          loading={$form.processing}
          name="description"
          label="Description"
          size="xs"
          placeholder="Enter the description of the event"
          bind:value={$form.description}
          error={$form.errors.description}
        />

        <div class="mt-2">
          <Button loading={$form.processing} type="submit" size="xs" on:click={(e) => handleSubmit(e)}>Save Event</Button>
        </div>
      </form>
    </div>
  </section>
</Layout>
