<script>
  import Layout from '@/Layouts/User.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, inertia} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {IconGoogleColor, IconMicrosoftColor, IconTick} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
</script>

<Seo title={`Pitch ${$page.props.profile.name}`} />

<Layout profile={$page.props?.profile}>
  <div class="wrap">
    <div class="head">
      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Write your pitch</p>
      </div>

      <span class="ellipsis"></span>

      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Make an offer</p>
      </div>

      <span class="ellipsis"></span>
      <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
    </div>

    <div class="description">
      <p><strong>Pitchfire requires connecting your calendar in order to send or receive pitches</strong>.</p>
      <ul>
        <li>Connecting your calendar makes it easy to book a meeting should you be interested.</li>
        <li>If you submit a pitch to someone else, and they want to meet, this makes it one click.</li>
        <li>This allows us not to worry about calendar invites going to spam.</li>
        <li>Don’t worry, we’re not doing anything shady.. we don’t store, sell, or steal data from your calendar.</li>
        <li>Your super secret mysterious meetings are safe with us.</li>
      </ul>
    </div>

    <div class="items">
      {#if $page.props?.auth?.login_provider === 'google'}
        <div class="item">
          {#if $page.props?.googleConnected}
            <div>
              <Icon data={IconGoogleColor} size="xl" />
              <span>Google Calendar Connected</span>
              <span class="status"></span>
            </div>
          {:else}
            <a href={$page.props?.googleAuthUrl}>
              <Icon data={IconGoogleColor} size="xl" />
              <span>Connect your Google calendar</span>
              <span class="status"></span>
            </a>
          {/if}
        </div>
      {:else if $page.props?.auth?.login_provider === 'microsoft'}
        <div class="item">
          {#if $page.props?.microsoftConnected}
            <div>
              <Icon data={IconMicrosoftColor} size="xl" />
              <span>Microsoft Calendar Connected</span>
              <span class="status"></span>
            </div>
          {:else}
            <a href={$page.props?.microsoftAuthUrl}>
              <Icon data={IconMicrosoftColor} size="xl" />
              <span>Connect your Microsoft calendar</span>
              <span class="status"></span>
            </a>
          {/if}
        </div>
      {:else}
        <div class="item">
          {#if $page.props?.googleConnected}
            <div>
              <Icon data={IconGoogleColor} size="xl" />
              <span>Google Calendar Connected</span>
              <span class="status"></span>
            </div>
          {:else}
            <a href={$page.props?.googleAuthUrl}>
              <Icon data={IconGoogleColor} size="xl" />
              <span>Connect your Google calendar</span>
              <span class="status"></span>
            </a>
          {/if}
        </div>

        <div class="item">
          {#if $page.props?.microsoftConnected}
            <div>
              <Icon data={IconMicrosoftColor} size="xl" />
              <span>Microsoft Calendar Connected</span>
              <span class="status"></span>
            </div>
          {:else}
            <a href={$page.props?.microsoftAuthUrl}>
              <Icon data={IconMicrosoftColor} size="xl" />
              <span>Connect your Microsoft calendar</span>
              <span class="status"></span>
            </a>
          {/if}
        </div>
      {/if}
    </div>

    <div class="actions">
      <a class="back" href="/pitch/{$page.props?.pitch?.id}/set-rate" use:inertia={{
        preserveState: true,
        preserveScroll: true,
      }}>&lt; Back</a>

      {#if $page.props?.googleConnected || $page.props?.microsoftConnected}
        <Button href="/pitch/{$page.props?.pitch?.id}/payment">Next</Button>
      {/if}
    </div>

    <div class="step-indicator">
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
         class="dot active" use:inertia={{preserveScroll: true}}
      >
      </a>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }

    p {
      @apply text-base mb-2 text-white;
    }

    li {
      @apply text-white;
      list-style-type: "🔥";
    }

    .items {
      @apply mt-4;
    }

    .item {
      @apply mt-4;

      a,
      div {
        @apply rounded-lg border border-blue p-3 justify-center font-semibold inline-flex gap-5 items-center text-blue text-base leading-none;
      }

      .status {
        @apply w-2 h-2 block rounded-full;
      }

      a {
        @apply cursor-pointer bg-white hover:bg-blue-50 hover:text-blue focus:bg-blue-50 focus:ring-2 focus:outline-none no-underline;

        .status {
          @apply bg-red-600;
        }
      }

      div {
        .status {
          @apply bg-green-600;
        }
      }
    }

    .actions {
      @apply flex justify-between mt-4 items-center text-white;
    }
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
