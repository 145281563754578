<script>
  import {inertia, page, router, useForm} from "@inertiajs/svelte";
  import {onMount} from "svelte";
  import Layout from "@/Layouts/User.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {
    IconGoogle, IconLinkedIn,
    IconMicrosoft,
    IconTick
  } from "@/Components/Assets/Icons/index.js";
  import Button from "@/Components/Buttons/Button.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import {getAuthUrl} from "@/Lib/auth.js"

  let googleAuthUrl = '';
  let microsoftAuthUrl = '';
  let linkedinAuthUrl = '';
  let emailAuthUrl = '';

  let storage = $page.props.profile?.source === '5x5' ? '_bq' : '';

  let form = useForm({
    body: '',
    request_fee: $page.props?.profile.pitch_price ?? 25,
    buyer_id: $page.props?.profile?.id,
    type : $page.props?.profile?.source === '5x5' ? 'profiles' : 'users'
  });

  onMount(() => {
    if ($page.props?.auth?.loggedIn) {
      let pitchFormData;
      let pitchData = localStorage.getItem(`pitch_data${storage}_${$page.props.profile?.id }`);
      if (pitchData) {
        pitchFormData = JSON.parse(pitchData);

        $form.request_fee = pitchFormData.request_fee;
        $form.body = pitchFormData.body;
        $form.type = pitchFormData.type === '5x5' ? 'profiles' : 'users'
        $form.post('/pitch');
      } else {
        router.visit("/@" + $page.props?.profile?.slug + "/pitch", {preserveScroll: true});
      }
    }
    else {
      googleAuthUrl = getAuthUrl('/auth/google/redirect', true);
      microsoftAuthUrl = getAuthUrl('/auth/microsoft/redirect', true);
      linkedinAuthUrl = getAuthUrl('/auth/linkedin/redirect', true);
      emailAuthUrl = getAuthUrl('/auth/login', true);
    }
  });
</script>

<Layout profile={$page.props?.profile}>
  <Seo
    title={`Pitch ${$page.props?.profile?.name}`}
    breadcrumbs={[
      { name: 'Marketplace', slug: '/marketplace' },
      { name: $page.props?.profile?.name, slug: `/@${$page.props?.profile?.slug}` },
      { name: `Pitch ${$page.props?.profile?.name}` },
    ]}
    metaDescription={$page.props?.profile?.about ? $page.props?.profile?.about : `Pitch ${$page.props?.profile?.name}`}
    ogImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
    twitterImage={{
      url: $page.props?.common.app_url + '/og-images/users/' + $page.props?.profile?.slug + '.png',
      alt: $page.props?.profile?.name,
    }}
  />

  <div class="pitch">
    <div class="head">
      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Write your pitch</p>
      </div>

      <span class="ellipsis"></span>

      <div>
        <div class="step">
          <Icon data={IconTick} size="lg" class="fill-white" />
        </div>
        <p>Make an offer</p>
      </div>

      <span class="ellipsis"></span>
      <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
    </div>

    <div class="content">
      {#if $page.props?.auth?.loggedIn}
        <p>Saving your pitch...</p>
        <Loader />
      {:else}
        <h2>Create your Pitchfire Account</h2>

        <div class="actions">
          <Button color="red" dynamic={false} href={googleAuthUrl} loading={googleAuthUrl === ''}>
            <Icon size="xxl" data={IconGoogle} class="fill-white" />
            <span>Continue with Google</span>
          </Button>

          <Button color="red" dynamic={false} href={microsoftAuthUrl} loading={microsoftAuthUrl === ''}>
            <Icon size="xxl" data={IconMicrosoft} class="fill-white" />
            <span>Continue with Microsoft</span>
          </Button>

          <Button color="red" dynamic={false} href={linkedinAuthUrl} loading={linkedinAuthUrl === ''}>
            <Icon size="xxl" data={IconLinkedIn} class="fill-white" />
            <span>Continue with LinkedIn</span>
          </Button>
        </div>

        <p><a href={emailAuthUrl} class:disabled={emailAuthUrl === ''}>Continue with Email</a></p>
      {/if}
    </div>

    <div class="step-indicator">
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}` : `/@${$page.props?.profile?.slug}/pitch`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <a href={$page.props?.pitch?.id ? `/pitch/${$page.props.pitch.id}/set-rate` : `/@${$page.props?.profile?.slug}/set-rate`}
         class="dot" use:inertia={{preserveScroll: true}}
      >
      </a>
      <div class="dot active"></div>
      <div class="dot"></div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10;

    .head {
      @apply flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center justify-between text-white mb-4;

      & > div {
        @apply flex flex-row items-center justify-between;
      }

      .step {
        @apply bg-grey-400 w-8 h-8 rounded-full flex items-center justify-center text-white font-semibold text-base leading-none;
      }

      .ellipsis {
        background-image: url(/assets/dash.svg);
        @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mr-4;
      }

      p {
        @apply my-0 ml-2 mr-4;
      }

      img {
        @apply hidden sm:block w-12 rounded-lg
      }
    }
  }

  h2 {
    @apply text-white text-2xl font-semibold mb-8 mt-12 text-center;
  }

  .actions {
    @apply flex flex-wrap justify-center items-center gap-4;

    span {
      @apply sm:text-nowrap;
    }
  }

  .content {
    @apply mb-12 flex flex-col items-center gap-4;

    p {
      @apply text-center text-white mt-8 text-sm;
    }
  }

  .step-indicator {
    @apply flex justify-center items-center my-4;

    .dot {
      @apply w-3 h-3 rounded-full bg-grey-100 mx-2;

      &.active {
        @apply bg-red-500;
      }
    }
  }
</style>
