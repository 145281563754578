<script>
  import Layout from "@/Layouts/Auth.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import { page } from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import { onMount } from 'svelte';
  import {warning} from "@/Lib/notices.js";
  import {getAuthUrl} from "@/Lib/auth.js"
  import { IconGoogle, IconLinkedIn, IconMicrosoft } from "@/Components/Assets/Icons/index.js";

  let googleAuthUrl = '';
  let microsoftAuthUrl = '';
  let linkedinAuthUrl = '';
  let emailAuthUrl = '';

  let disabled = false;

  const isWebview = (userAgent) => {
    return /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(userAgent)
  }

  onMount(() => {
    if (isWebview(window.navigator.userAgent)) {
      disabled = true;
      warning('Unable to login. Please use a supported web browser.')
    }

    googleAuthUrl = getAuthUrl('/auth/google/redirect');
    microsoftAuthUrl = getAuthUrl('/auth/microsoft/redirect');
    linkedinAuthUrl = getAuthUrl('/auth/linkedin/redirect');
    emailAuthUrl = getAuthUrl('/auth/login');
  });
</script>

<Seo title="Home" />

<Layout title="Create an account">
  {#if $page.props.auth?.loggedIn}
    <Profile
      image={$page.props.auth?.user?.avatar}
      imageAlt={$page.props.auth?.user?.name}
      subImage={$page.props.auth?.org?.logo}
      subImageAlt={$page.props.auth?.org?.name}
      title={$page.props.auth?.user?.name}
      subTitle={$page.props.auth?.user?.email}
      href="/app/respond"
    />

    <div class="buttons">
      <Button color="tertiary" class="!text-sm" href="/app/respond">Continue</Button>
      <Button color="tertiary" class="!text-sm" href="/logout">Log out</Button>
    </div>
  {:else}
    <div class="btns">
      <Button dynamic={false} {disabled} class="!text-sm !gap-3" href={googleAuthUrl} loading={googleAuthUrl === ''}>
        <Icon size="lg" data={IconGoogle} class="fill-white" />
        <span>Continue with Google</span>
      </Button>

      <Button dynamic={false} {disabled} class="!text-sm !gap-3" href={microsoftAuthUrl} loading={microsoftAuthUrl === ''}>
        <Icon size="lg" data={IconMicrosoft} class="fill-white" />
        <span>Continue with Microsoft</span>
      </Button>

      <Button dynamic={false} {disabled} class="!text-sm !gap-3" href={linkedinAuthUrl} loading={linkedinAuthUrl === ''}>
        <Icon size="lg" data={IconLinkedIn} class="fill-white" />
        <span>Continue with LinkedIn</span>
      </Button>
    </div>

    <p><a href={emailAuthUrl} class:disabled={emailAuthUrl === ''}>Continue with Email</a></p>
  {/if}
</Layout>

<style lang="postcss">
  .buttons {
    @apply flex flex-row flex-wrap gap-3 mt-8;
  }

  .btns {
    @apply flex flex-col gap-3 sm:px-8 max-w-xs mx-auto;
  }

  p {
    @apply text-center text-xs sm:text-sm m-0 mt-6;

    a {
      @apply no-underline hover:underline focus:underline text-blue;

      &.disabled {
        @apply cursor-not-allowed pointer-events-none hover:no-underline focus:no-underline;
      }
    }
  }
</style>
