<script>
  // Adapted from https://github.com/themesberg/flowbite-svelte/blob/main/src/lib/buttons/Button.svelte

  import classNames from 'classnames';
  import {getContext} from 'svelte';
  import {inertia} from "@inertiajs/svelte";

  const group = getContext('group');

  /** @type {string} href */
  export let href = undefined;

  /** @type {boolean} disabled */
  export let disabled = undefined;

  /** @type {string} btnClass */
  export let btnClass = undefined;

  /** @type {import('@/Lib/types/component.d').ButtonType} type */
  export let type = 'button';

  export let loading = false;

  export let label = '';

  /** @type {import('@/Lib/types/component.d').SizeType} size */
  export let size = 'md';

  /** @type {import('@/Lib/types/component.d').ColorType} color */
  export let color = 'primary';

  export let dynamic = !!href;

  /** @type {string} buttonClass */
  let buttonClass;
  $: buttonClass = btnClass !== '' && btnClass !== undefined ? btnClass : classNames(group ?? size, color, $$props.class, group && 'grouped');

  const conditionalInertia = dynamic ? inertia : () => {};
</script>

<svelte:element
  {...$$restProps}
  class={buttonClass}
  disabled={disabled || loading}
  {href}
  on:change
  on:click
  on:keydown
  on:keyup
  on:mouseenter
  on:mouseleave
  use:conditionalInertia
  role="button"
  tabindex="0"
  this={href && !disabled ? 'a' : 'button'}
  type={href ? undefined : type}
>
  {#if loading}
    <svg class={classNames(color === 'tertiary' ? 'text-black' : 'text-white')} xmlns="http://www.w3.org/2000/svg"
         fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
  {/if}
  <slot>{label}</slot>
</svelte:element>

<style lang="postcss">
  button,
  a {
    @apply justify-center font-semibold rounded-lg border focus:ring-2 focus:outline-none cursor-pointer inline-flex gap-2 items-center;

    &:disabled {
      @apply cursor-not-allowed;
    }
  }

  a {
    @apply no-underline;
  }

  svg {
    @apply block w-4 h-4 animate-spin;
  }

  .grouped {
    @apply !rounded-none border-0 focus:ring-blue-300;
  }

  .primary {
    @apply text-white bg-blue hover:bg-blue-600 border-blue focus:ring-blue-300 disabled:border-blue-100 disabled:bg-blue-100 disabled:text-blue-400;
  }

  .secondary {
    @apply text-white bg-green-700 hover:bg-green-900 border-green focus:ring-green-300 disabled:border-grey-200 disabled:bg-grey-200 disabled:text-grey-500;
  }

  .tertiary {
    @apply text-blue bg-white hover:bg-blue-50 hover:text-blue border-blue focus:bg-blue-50 disabled:border-grey-200 disabled:bg-white disabled:text-grey-500;
  }

  .gradient {
    @apply bg-grad-primary border-0 text-white hover:bg-blue hover:bg-none disabled:bg-grad-primary-disabled disabled:text-blue-400;
  }

  .red {
    @apply text-white bg-red hover:bg-red-600 border-red focus:ring-red-300 disabled:border-red-100 disabled:bg-red-100 disabled:text-red-400;
  }

  .xxs, .xs, .sm, .md, .lg, .xl {
    @apply px-4 leading-none;
  }

  .xxs {
    @apply py-1 text-xs px-2.5;
  }

  .xs {
    @apply py-1.5 text-xs;
  }

  .sm {
    @apply py-2 text-sm;
  }

  .md {
    @apply py-2.5 text-base;
  }

  .lg {
    @apply py-3 text-lg;
  }

  .xl {
    @apply py-3.5 text-xl;
  }
</style>
