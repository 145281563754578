<script>
  import Layout from "@/Layouts/CampaignLinks.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {useForm} from "@inertiajs/svelte";
  import Range from "@/Components/Forms/Range.svelte";
  import CKEditor from "@/Components/Forms/CKEditor.svelte";

  let processing = false;

  let form = useForm({
    title: '',
    amount: 25,
    spend_limit: 100,
    pitch_body: '',
  });

  const handleSave = (event) => {
    event.preventDefault();
    $form.post('/app/campaign-links/store');
  };
</script>

<Seo title="Campaign Links" />

<Layout>
  <form method="post" on:submit={(event) => handleSave(event)}>
    <div>
      <Input
        name="title"
        size="sm"
        label="Campaign Name"
        required
        placeholder="Enter your campaign name"
        error={$form.errors.title}
        bind:value={$form.title}
      />
    </div>

    <div>
      <Range
        label="How much do vou want to offer your prospects to respond to your pitch?"
        description="Note: the more you offer, the more likely you are to get a response."
        name="amount"
        minLabel="$25"
        min="25"
        maxLabel="$500"
        max="500"
        valuePrefix="$"
        error={$form.errors.amount}
        bind:value={$form.amount}
      />
    </div>

    <div>
      <Input
        name="spend_limit"
        size="sm"
        type="number"
        required
        label="What Is the maximum you'd like to spend on this campaign?"
        description="Note: your balance will only get depleted when your prospect responds. When your balance hits zero, your pitch link will expire."
        placeholder="100"
        min="100"
        error={$form.errors.spend_limit}
        bind:value={$form.spend_limit}
      >
        <span slot="left" class="text-gray-500">USD</span>
      </Input>
    </div>

    <div>
      <CKEditor
        name="pitch_body"
        size="sm"
        label="Write your Pitch"
        bind:value={$form.pitch_body}
        error={$form.errors.pitch_body}
        placeholder="Every buyer who clicks your link will see this pitch."
        required
        maxLength="1000"
        layout="full"
      />
    </div>

    <div class="actions">
      <Button type="button" color="tertiary" href="/app/campaign-links" dynamic>Cancel</Button>
      <Button type="submit" color="secondary" on:submit={(event) => handleSave(event)} disabled={$form.processing || processing}>Next</Button>
    </div>
  </form>
</Layout>

<style lang="postcss">
  form {
    @apply flex flex-col gap-8 xl:w-3/4;

    .actions {
      @apply flex gap-4 items-center justify-between;

      div {
        @apply flex gap-8;
      }
    }
  }
</style>
