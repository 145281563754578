<script>
  import classNames from "classnames";
  import { page } from "@inertiajs/svelte";
  import Logo from "@/Components/Assets/Logo.svelte";
  import Button from "../Buttons/Button.svelte";
  import Input from "../Forms/Input.svelte";
  import { success, failure } from "@/Lib/notices";
  import api from "@/Lib/api.js";

  /** @satisfies theme */
  export let theme = "default";

  export let credit = $page.props?.credit;

  let processing = false;
  let code = null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.post('/app/redeem-coupon', { code })
      .then((res) => {
        if (res?.data?.success) {
          credit = res?.data?.credit;
          if (res?.data?.coupon?.amount) {
            code = '';
            success(res?.data?.message);
          } else {
            failure(res?.data?.message);
          }
        } else {
          failure(res?.data?.message);
        }
      })
      .catch((error) => {
        failure(error?.response?.data?.message);
      });
  };
</script>

<div class={classNames("wrap", theme, $$props.class)}>
  <div class="main">
    <div class="logo">
      <Logo type="icon" class="!w-full !h-auto" />
    </div>
    <div class="card">
      <div class="details">
        <div class="details_inner">
          <h4>Pitchfire Credits</h4>
          {#if credit > 0}
            <p>Since you have credit in your account, <br />you can use it to pay for pitches.</p>
          {:else}
            <p>Credits can be used to pay for pitches</p>
          {/if}
        </div>
        <div class="details_inner_second">
          <p>Balance</p>
          <h4>${credit ?? 0}</h4>
        </div>
      </div>

      <slot />

      <div class="redeem">
        <Input bind:value={code} placeholder="Coupon code" size="xs" />
        <Button size="xs" on:click={(e) => handleSubmit(e)} loading={processing}>Submit</Button>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .wrap {
    @apply bg-grey-100 rounded-xl gap-4 p-4 sm:min-w-[28rem] md:min-w-[30rem];

    &.active {
      @apply bg-blue-100/95;
    }
  }

  .main {
    @apply flex items-center max-w-md;
  }

  .logo {
    @apply hidden sm:block w-16 sm:w-20 md:w-24 shrink-0 py-2.5 px-6 bg-white mr-4;
  }

  .card {
    @apply w-full;

    h4 {
      @apply text-lg  leading-none;
    }

    p {
      @apply text-xs m-0 mt-2;

      br {
        @apply sm:block;
      }
    }

    .details {
      @apply flex flex-wrap gap-x-6 gap-y-4 sm:gap-y-2 justify-between;
    }

    .details_inner_second {
      @apply sm:text-end;

      p {
        @apply mb-1;
      }
    }
  }
  .redeem {
    @apply flex gap-4 items-center justify-start ml-auto mt-3;
  }
</style>
